import { Injectable } from '@angular/core';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { User } from '../User';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Society } from '../Society';
import { ApiResult } from '../ApiResult';
import { environment } from '../../../environments/environment';
import { Order } from '../Order';
import { OrderSumUp } from '../OrderSumUp';
import { OrderFilter } from '../Filters';

@Injectable({ providedIn: 'root' })
export class OrdersService {
  private ordersUrl = environment.apiUrl + '/Orders'; // URL to web api
  private ordersSumUpUrl = environment.apiUrl + '/OrdersSumUp'; // URL to web api

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient, public router: Router) {}

  getOrder(idOrder: string): Observable<Order> {
    console.log('user service getSocieties');
    return this.http
      .get<Order>(this.ordersUrl + '/' + idOrder)
      .pipe(tap((_) => console.log('fetched order')));
  }

  getOrders(): Observable<Order[]> {
    console.log('user service getOrders');
    return this.http
      .get<Order[]>(this.ordersUrl)
      .pipe(tap((_) => console.log('fetched order')));
  }

  getOrdersSumUp(
    page: number,
    pageSize: number,
    orderFilter: OrderFilter
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());

    if (orderFilter != undefined && orderFilter.id && orderFilter.id > 0) {
      params = params.set('orderId', orderFilter.id.toString());
    }
    if (
      orderFilter != undefined &&
      orderFilter.status !== undefined &&
      orderFilter.status !== ''
    ) {
      params = params.set('status', orderFilter.status);
    }
    if (
      orderFilter != undefined &&
      orderFilter.date &&
      !isNaN(orderFilter.date.getTime())
    ) {
      params = params.set('date', orderFilter.date.toISOString());
    }
    return this.http
      .get<any>(this.ordersSumUpUrl, { params })
      .pipe(tap((_) => console.log('fetched getOrdersSumUp page', page)));
  }

  getLastOrderId(): Observable<number> {
    console.log('user service getSocieties');
    return this.http
      .get<number>(this.ordersUrl)
      .pipe(tap((_) => console.log('fetched order')));
  }

  createOrder(order: Order, observer) {
    this.http
      .post<Order>(this.ordersUrl, order, this.httpOptions)
      .subscribe(observer);
  }

  modifyOrder(order: Order, observer) {
    console.log('HTTP modifyOrder');
    console.log(order);

    this.http
      .patch<Order>(this.ordersUrl, order, this.httpOptions)
      .subscribe(observer);
  }
}
