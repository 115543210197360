<h1>Commandes</h1>

<div style="margin-bottom: 1rem;display: flex;place-content: space-between;">
    <app-filter-button (pressed)="showFilterPanel=true"
        [numberFilters]="orderFilter ? orderFilter.nbFilters : 0"></app-filter-button>
    <div><a class="btn btn-black" (click)="togglePanel()"> Créer une commande </a>
        <button type="button" class="btn btn-no-background mr-0" (click)="createDispute()">
            Créer un litige
        </button>
    </div>
</div>

<app-choose-client-panel [showOrderPanel]="showOrderPanel"
    (closePanel)="showOrderPanel = false"></app-choose-client-panel>

<app-choose-order-filter-panel [showFilterPanel]="showFilterPanel" (closeFilterPanel)="closeFilterPanel()"
    (filterValueChanged)="filterChanges($event)"></app-choose-order-filter-panel>

<div class="card">
    <div class="card-body">
        <div class="table-responsive">
            <table class="table align-middle text-nowrap">
                <thead>
                    <tr>
                        <th>Date et heure</th>
                        <th>ID</th>
                        <th>Nom d'appel</th>
                        <th>Nombre d'articles</th>
                        <th>Statut</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let order of ordersSumUp">

                        <td>{{order.creationDate | date: 'dd.MM.yy | HH:mm'}}</td>
                        <td>{{order.id}}</td>
                        <td>{{order.callName}}</td>
                        <td>{{order.numberOfProducts}}</td>
                        <td>
                            <span style="width:100%" class="badge bg-warning" *ngIf="order.status == 'B'">À valider
                            </span>
                            <span style="width:100%" class="badge bg-success" *ngIf="order.status == 'T'">Validée</span>
                        </td>
                        <td><a class="link" href="/orders-details/{{order.id}}/{{order.societyId}}">Voir</a></td>

                    </tr>

                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer">
        <!--<button class="btn btn-primary" (click)="navigate('prev')" [disabled]="currentPage === 1">Précédent</button>-->
        <ng-container *ngFor="let page of getPagesToShow()">
            <button class="btn " (click)="goToPage(page)" [class.font-weight-bold]="currentPage === page">
                {{ page }}
            </button>
        </ng-container>
        <!--<span> {{currentPage}} / {{totalPages}}</span>-->
        <!--<button class="btn btn-primary" (click)="navigate('next')"
            [disabled]="currentPage === totalPages">Suivant</button>-->
    </div>
</div>