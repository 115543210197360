<div class="preloader" *ngIf="isLoading">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>

<a href="clients" class="returnToPrevious"><i-feather class="leftArrow" name="arrow-left"></i-feather> Retour au listing
    des clients</a>

<div class="card">
    <div class="container-fluid py-3">
        <div style="margin-bottom: 1rem;display: flex;place-content: end;">
            <button type="button" class="btn btn-black" (click)="validate()"> Enregistrer
            </button>
            <button type="button" class="btn btn-white" *ngIf="user && user.id"
                (click)="deleteClientWithConfirmation()"> Supprimer le client
            </button>
        </div>

        <div *ngIf="errorText" [@fadeInOut] class="alert show customize-alert fs-4 alert-danger fade">
            {{errorText}}
            <button type="button" aria-label="Close" class="btn-close btn-absolute-right"
                (click)="deleteMessages()"></button>
        </div>
        <div *ngIf="successText" [@fadeInOut] class="alert show customize-alert fs-4 alert-success fade">
            {{successText}}
            <button type="button" aria-label="Close" class="btn-close btn-absolute-right"
                (click)="deleteMessages()"></button>
        </div>

        <div class="row justify-content-left my-5">
            <div class="col-7">
                <form *ngIf="reactiveForm" [formGroup]="reactiveForm" #form="ngForm">
                    <input type="hidden" id="id" name="id" formControlName="id" />

                    <div class="row">
                        <div class="col mb-2">
                            <label for="status" class="form-label">Statut :</label>
                            <select formControlName="status" class="form-control form-control-sm"
                                style="appearance: auto;" id="status" name="status"
                                [class.is-invalid]="status.invalid && (status.dirty || status.touched)">
                                <option value="1">Actif</option>
                                <option value="0">Inactif</option>
                            </select>
                            <div *ngIf="status.invalid && (status.dirty || status.touched)" class="invalid-feedback">

                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="reactiveForm.get('isAdmin')?.value != true">
                        <div class="col mb-2">
                            <label for="groupName" class="form-label">Groupe :</label>
                            <input type="text" id="groupName" name="groupName" formControlName="groupName"
                                placeholder="Groupe" minlength="1" maxlength="250" class="form-control form-control-sm"
                                [class.is-invalid]="groupName.invalid && (groupName.dirty || groupName.touched)">
                            <div *ngIf="groupName.invalid && (groupName.dirty || groupName.touched)"
                                class="invalid-feedback">

                                <div *ngIf="groupName.errors?.['maxlength']">
                                    La taille du champ Groupe ne doit pas excéder 250 caractères.
                                </div>
                                <div *ngIf="reactiveForm.errors?.['groupNameMandatory']">
                                    <p>Le nom du groupe est obligatoire si il y a 2 sociétés ou plus.</p>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col mb-2">
                            <label for="phone" class="form-label">Téléphone :</label>
                            <input type="text" id="phone" name="phone" formControlName="phone"
                                placeholder="05 00 00 00 00" maxlength="10" class="form-control form-control-sm"
                                required [class.is-invalid]="phone.invalid && (phone.dirty || phone.touched)">
                            <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="invalid-feedback">
                                <div *ngIf="phone.errors?.['required']">
                                    Le champ téléphone est obligatoire
                                </div>
                                <div *ngIf="phone.errors?.['maxlength']">
                                    La taille du champ Téléphone ne doit pas excéder 32 caractères.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col mb-2">
                            <label for="email" class="form-label">E-mail :</label>
                            <input type="email" id="email" name="email" formControlName="email"
                                placeholder="contact@exemple.fr" maxlength="250" class="form-control form-control-sm"
                                [class.is-invalid]="email.invalid && (email.dirty || email.touched)">
                            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
                                <div *ngIf="email.errors?.['required']">
                                    Le champ e-mail est obligatoire
                                </div>

                                <div *ngIf="email.errors?.['maxlength']">
                                    La taille du champ E-mail ne doit pas excéder 255 caractères.
                                </div>
                                <div *ngIf="email.errors?.['email']">
                                    Format d'E-mail invalide
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col mb-2 inline-flex">
                            <label for="isAdmin" class="form-label form-label-custom-checkbox">Administrateur :</label>
                            <label class="custom-checkbox-container">
                                <input type="checkbox" id="isAdmin" name="isAdmin" formControlName="isAdmin" class=""
                                    hidden>
                                <span class="custom-checkbox"></span>
                                <label for="isAdmin" class="yes" class="custom-checkbox-label">Oui</label>
                            </label>

                        </div>
                    </div>

                    <div class="row" *ngIf="reactiveForm.get('isAdmin')?.value == true">
                        <div class="col mb-2">
                            <label for="firstName" class="form-label">Prénom :</label>
                            <input type="text" id="firstName" name="firstName" formControlName="firstName"
                                placeholder="Prénom" minlength="1" maxlength="250" class="form-control form-control-sm"
                                [class.is-invalid]="firstName.invalid && (firstName.dirty || firstName.touched)">
                            <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
                                class="invalid-feedback">
                                <div *ngIf="firstName.errors?.['required']">
                                    Le champ prénom est obligatoire
                                </div>
                                <div *ngIf="firstName.errors?.['maxlength']">
                                    La taille du champ Prénom ne doit pas excéder 255 caractères.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="reactiveForm.get('isAdmin')?.value == true">
                        <div class="col mb-2">
                            <label for="lastName" class="form-label">Nom :</label>
                            <input type="text" id="lastName" name="lastName" formControlName="lastName"
                                placeholder="Nom" minlength="1" maxlength="250" class="form-control form-control-sm"
                                [class.is-invalid]="lastName.invalid && (lastName.dirty || lastName.touched)">
                            <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
                                class="invalid-feedback">
                                <div *ngIf="lastName.errors?.['required']">
                                    Le champ nom est obligatoire
                                </div>
                                <div *ngIf="lastName.errors?.['maxlength']">
                                    La taille du champ Nom ne doit pas excéder 255 caractères.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="reactiveForm.get('isAdmin')?.value == true">
                        <div class="col mb-2">
                            <label for="password" class="form-label">Mot de passe :</label>
                            <input type="password" id="password" name="password" formControlName="password"
                                placeholder="" minlength="8" maxlength="250" class="form-control form-control-sm"
                                [class.is-invalid]="password.invalid && (password.dirty || password.touched)">
                            <div *ngIf="password.invalid && (password.dirty || password.touched)"
                                class="invalid-feedback">
                                <div *ngIf="password.errors?.['required']">
                                    Le mot de passe est obligatoire
                                </div>
                                <div *ngIf="password.errors?.['minlength']">
                                    La taille du mot de passe doit être au minimum de 8 caractères.
                                </div>
                                <div *ngIf="password.errors?.['maxlength']">
                                    La taille du mot de passe ne doit pas excéder 255 caractères.
                                </div>

                            </div>
                        </div>
                    </div>

                    <input type="file" id="fileInput" (change)="onFileSelected($event)" accept="image/*"
                        style="display: none;">
                    <div class="row" *ngIf="reactiveForm.get('isAdmin')?.value == true">
                        <label class="form-label">Photo de profil :</label>
                        <div class="navbar-brand">
                            <img id="selectedAvatar" class="selectedAvatar" [src]="avatarUrl" />
                            <div class="buttons">
                                <label for="fileInput" class="btn btn-black">
                                    <i class="fa-solid fa-pencil"></i>
                                    Modifier la photo
                                </label>

                                <button type="button" class="btn btn-white " (click)="removeSelectedAvatar()">
                                    <div class="button-content">
                                        <i class="fa-regular fa-circle-xmark"></i>
                                        Supprimer la photo
                                    </div>
                                </button>
                            </div>
                        </div>

                    </div>

                    <div class="row societies" *ngIf="reactiveForm.get('isAdmin')?.value != true">
                        <div class="col mb-2">
                            <div formArrayName="societies">
                                <div *ngFor="let item of formSocieties.controls; let i = index"
                                    style="display: flex;margin-bottom: 15px;">
                                    <div style="width: 30%;" class="form-label-society">Société n°{{i+1}}</div>
                                    <div class="society-card" [formGroupName]="i">
                                        <input type="hidden" id="id" name="id" formControlName="id" />
                                        <div class="line-society">
                                            <label for="code2b" class="form-label-society">Code
                                                2B :</label>
                                            <input style="width:60%" formControlName="code2b" placeholder="Code 2B"
                                                class="form-control form-control-sm" required
                                                [class.is-invalid]="getValidationErrors(i)?.code2b?.['invalid'] && (getValidationErrors(i)?.code2b?.['dirty'] || getValidationErrors(i)?.code2b?.['touched'])">
                                            <div *ngIf="getValidationErrors(i)?.code2b?.['invalid'] && (getValidationErrors(i)?.code2b?.['dirty'] || getValidationErrors(i)?.code2b?.['touched'])"
                                                class="invalid-feedback">
                                                Champ Code2B nécessaire
                                            </div>
                                        </div>
                                        <div class="line-society">
                                            <label for="callName" class="form-label-society" style="">Nom d'appel
                                                :</label>
                                            <input style="width:60%" formControlName="callName"
                                                placeholder="Nom d'appel" class="form-control form-control-sm" required>
                                        </div>
                                        <div class="line-society">
                                            <label for="societyName" class="form-label-society">Nom de société
                                                :</label>
                                            <input style="width:60%" formControlName="societyName"
                                                placeholder="Nom de société" class="form-control form-control-sm"
                                                required>
                                        </div>
                                        <div class="line-society">

                                            <label for="address" class="form-label-society">Adresse :</label>
                                            <textarea style="width:60%;vertical-align: top;" formControlName="address"
                                                placeholder="Adresse" class="form-control form-control-sm" rows="2"
                                                required></textarea>
                                        </div>
                                        <button *ngIf="item.value['id'] == 0" (click)="removeSociety(i)"
                                            class="btn btn-white">Retirer cette
                                            société</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="reactiveForm.get('isAdmin')?.value != true"
                        style="margin-bottom: 1rem;display: flex;place-content: end;">
                        <button class="btn btn-white" (click)="addSociety()">Ajouter une société</button>
                    </div>
                    <div
                        *ngIf="reactiveForm.get('isAdmin')?.value != true && formSocieties.errors?.['atLeastOneSociety']">
                        <p class="invalid-feedback" style="display:block;">Il doit y avoir au moins une société</p>
                    </div>


                </form>
            </div>
            <div class="col-5 " style="text-align: right;"
                *ngIf="user && user.id && reactiveForm.get('isAdmin')?.value != true">
                <qrcode [qrdata]="getClientUrl()" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
                <div>
                    <div class="connexionLink">Lien de connexion : </div>
                    <div><span *ngIf="linkCopiedMessage" class="link-copied-message">{{ linkCopiedMessage
                            }}</span><button type="button" class="btn btn-black " (click)="copyLinkToClipboard()"><i
                                class="fa-regular fa-copy"></i>Copier le lien </button>

                    </div>
                    <div><button type="button" class="btn btn-white " (click)="sendLinkBySms()"><i
                                class="fa-regular fa-message"></i>
                            Envoyer par
                            SMS </button></div>
                    <div> <button type="button" class="btn btn-white " (click)="sendLinkByEmail()"> <i
                                class="fa-regular fa-paper-plane"></i>
                            Envoyer par E-mail </button></div>
                </div>
            </div>

        </div>

    </div>
</div>