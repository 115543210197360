import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { FormArray } from '@angular/forms';
import { Product } from '../../dao/Product';
import { Society } from '../../dao/Society';
import { Clipboard } from '@angular/cdk/clipboard';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ProductsService } from 'src/app/dao/services/products.service';
import { DomSanitizer } from '@angular/platform-browser';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { environment } from 'src/environments/environment';
import { HelpersService } from 'src/app/dao/services/helpers.service';

@Component({
  selector: 'app-products-details',
  templateUrl: './products-details.component.html',
  styleUrls: ['./products-details.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: 0,
        })
      ),
      transition('void <=> *', animate(500)),
    ]),
  ],
})
export class ProductsDetailsComponent implements OnInit {
  constructor(
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private productsService: ProductsService,
    private clipboard: Clipboard,
    private route: ActivatedRoute,
    private router: Router,
    public helpersService: HelpersService
  ) {
    this.productFromDb = {} as Product;
    this.product = {} as Product;
  }

  successText: string;
  errorText: String;
  isLoading: boolean;
  reactiveForm!: FormGroup;
  product: Product;
  productFromDb: Product;
  possibleValuesForQuantityType: string[] = ['C', 'K', 'P'];
  possibleValuesForPackaging: string[] = ['F', 'S'];
  possibleValuesForOrigin: string[] = ['F', 'E', 'B'];
  selectedImage: File | null = null;
  defaultImage: boolean = false;

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      // Access URL parameters using paramMap
      const id = params.get('id');
      if (id != undefined) {
        this.productsService.getProduct(id).subscribe((product: Product) => {
          console.log('Product Service get Product ');
          console.log(product);
          this.product = product;
          if (this.product.quantityType == null) {
            this.product.quantityType = '';
          }
          if (this.product.packaging == null) {
            this.product.packaging = '';
          }
          if (this.product.origin == null) {
            this.product.origin = '';
          }
          this.productFromDb = product;
          this.reactiveForm = this.fb.group({
            capabilities: this.generateProductFormGroup(product),
            id: new FormControl(this.product.id),
            image: new FormControl(this.product.image),
            status: new FormControl(this.product.status, Validators.required),
            name: new FormControl(this.product.name, Validators.required),
            shortName: new FormControl(this.product.shortName),
            creationDate: new FormControl(this.product.creationDate),
            category: new FormControl(
              this.product.category,
              Validators.required
            ),
            position: new FormControl(this.product.position),
            quantityType: new FormControl(this.product.quantityType),
            packaging: new FormControl(this.product.packaging),
            origin: new FormControl(this.product.origin),
            angus: new FormControl(this.product.angus),
            opportunity: new FormControl(this.product.opportunity),
            isInBdc: new FormControl(this.product.isInBdc),
          });
        });
      } else {
        this.product.quantityType = '';
        this.product.packaging = '';
        this.product.origin = '';
        this.reactiveForm = this.fb.group({
          capabilities: this.generateProductFormGroup(this.product),
          id: new FormControl(this.product.id),
          image: new FormControl(this.product.image),
          status: new FormControl(this.product.status, Validators.required),
          name: new FormControl(this.product.name, Validators.required),
          shortName: new FormControl(this.product.shortName),
          creationDate: new FormControl(this.product.creationDate),
          category: new FormControl(this.product.category, Validators.required),
          position: new FormControl(this.product.position),
          quantityType: new FormControl(this.product.quantityType),
          packaging: new FormControl(this.product.packaging),
          origin: new FormControl(this.product.origin),
          angus: new FormControl(this.product.angus),
          opportunity: new FormControl(this.product.opportunity),
          isInBdc: new FormControl(this.product.isInBdc),
        });
      }
    });
  }

  onFileSelected(event: Event) {
    //const file = event.target.files[0];
    //this.selectedFile = file; // Set selected file in image service

    const inputElement = event.target as HTMLInputElement;
    if (inputElement && inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const selectedImageElement = document.getElementById(
          'selectedImage'
        ) as HTMLImageElement;
        if (selectedImageElement) {
          selectedImageElement.src = e.target?.result as string;
          //selectedImageElement.style.display = 'block'; // Display the image
        }
      };
      this.selectedImage = file;
      /*
      this.reactiveForm.patchValue({
        imageFile: this.selectedImage, // Update the form control value with the selected image file
      });
      */
      reader.readAsDataURL(file); // Read the selected file as a data URL
    }
  }

  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  deleteProductWithConfirmation() {
    if (window.confirm('Etes vous sur de vouloir supprimer ce produit ?')) {
      this.deleteProduct();
    } else {
      console.log('Delete canceled');
    }
  }

  deleteProduct() {
    if (
      this.product.id == null ||
      this.product.id == undefined ||
      this.product.id == 0
    ) {
      this.router.navigate(['/products']);
    } else {
      const observer = {
        next: () => {
          this.router.navigate(['/products']);
        },
        error: (error: HttpErrorResponse) => {
          // Handle errors here
          if (error.error instanceof ErrorEvent) {
            // Client-side error
            this.setErrorText(error.error.message);
          } else {
            // Server-side error
            console.log(error);
            if (error.error) {
              this.setErrorText(error.error);
            } else {
              this.setErrorText(error.message);
            }
          }
        },
      };

      this.productsService.deleteProduct(this.productFromDb, observer);
    }
  }

  get id() {
    return this.reactiveForm.get('id')!;
  }

  get name() {
    return this.reactiveForm.get('name')!;
  }

  get shortName() {
    return this.reactiveForm.get('shortName')!;
  }

  get status() {
    return this.reactiveForm.get('status')!;
  }

  get creationDate() {
    return this.reactiveForm.get('creationDate')!;
  }

  get category() {
    return this.reactiveForm.get('category')!;
  }

  get quantityType() {
    return this.reactiveForm.get('quantityType')!;
  }

  get position() {
    return this.reactiveForm.get('position')!;
  }

  get packaging() {
    return this.reactiveForm.get('packaging')!;
  }
  get origin() {
    return this.reactiveForm.get('origin')!;
  }
  get angus() {
    return this.reactiveForm.get('angus')!;
  }
  get opportunity() {
    return this.reactiveForm.get('opportunity')!;
  }
  get isInBdc() {
    return this.reactiveForm.get('isInBdc')!;
  }
  get image() {
    return this.reactiveForm.get('image')!;
  }

  get imageUrl() {
    this.defaultImage = !(
      this.product.image != null &&
      this.product.image != undefined &&
      this.product.image != 'null'
    );
    return this.defaultImage
      ? environment.productImagesUrl + 'default.jpg'
      : environment.productImagesUrl + this.product.image;
  }

  onValueQuantityTypeChanged(event: any, value: string) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.product.quantityType = this.product.quantityType + value;
    } else {
      this.product.quantityType = this.product.quantityType.replaceAll(
        value,
        ''
      );
    }

    this.quantityType.setValue(this.product.quantityType);
  }

  onValuePackagingChanged(event: any, value: string) {
    const isChecked = event.target.checked;
    console.log(
      'onValuePackagingChanged\nthis.product.packaging : ' +
        this.product.packaging +
        '\nisChecked : ' +
        isChecked +
        '\nvalue : -' +
        value +
        '-'
    );
    if (isChecked) {
      this.product.packaging = this.product.packaging + value;
    } else {
      console.log('Before Replace all : ' + this.product.packaging);
      this.product.packaging = this.product.packaging.replaceAll(value, '');
      console.log('After Replace all : ' + this.product.packaging);
    }
    console.log(
      'onValuePackagingChanged\nthis.product.packaging : ' +
        this.product.packaging +
        '\nisChecked : ' +
        isChecked +
        '\nvalue : ' +
        value
    );
    this.packaging.setValue(this.product.packaging);
  }

  onValueOriginChanged(event: any, value: string) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.product.origin = this.product.origin + value;
    } else {
      this.product.origin = this.product.origin.replaceAll(value, '');
    }
    this.origin.setValue(this.product.origin);
  }

  markFormGroupControlsAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  getFormValidationErrors() {
    let ret: string = '';

    const errors: any = {};
    Object.keys(this.reactiveForm.controls).forEach((key) => {
      const controlErrors = this.reactiveForm.get(key)?.errors;
      if (controlErrors) {
        errors[key] = controlErrors;
        ret += controlErrors;
      }
    });

    return ret.trim();
  }

  public deleteMessages() {
    this.successText = '';
    this.errorText = '';
  }

  private setSuccessText(message: string) {
    this.successText = message;

    setTimeout(() => {
      this.successText = ''; // Retirer le message après 5 secondes
    }, 5000);
  }

  private setErrorText(message: string) {
    this.errorText = message;
    /*
    setTimeout(() => {
      this.successText = ''; // Retirer le message après 5 secondes
    }, 5000);

    */
  }

  isErrorsWithEmptyFields(productData: any) {
    let error = false;

    if (productData.quantityType.trim() === '') {
      this.setErrorText('Le Type de quantité est obligatoire');
      error = true;
    }
    if (productData.packaging.trim() === '') {
      this.setErrorText('Le Conditionnement est obligatoire');
      error = true;
    }
    if (productData.origin.trim() === '') {
      this.setErrorText("L'origine est obligatoire");
      error = true;
    }

    return error;
  }

  public validate(): void {
    this.setErrorText('');
    this.isLoading = true;
    let errorString = this.getFormValidationErrors();
    if (this.reactiveForm.invalid && errorString.length > 0) {
      console.log('Form invalid');

      console.log('ERROR String -' + errorString + '-');

      for (const control of Object.keys(this.reactiveForm.controls)) {
        this.reactiveForm.controls[control].markAsTouched();
      }
      this.isLoading = false;
      return;
    }

    const productData = this.reactiveForm.value;
    if (productData.shortName === 'null') {
      this.reactiveForm.patchValue({ shortName: null });
    }

    if (this.isErrorsWithEmptyFields(productData) == true) {
      this.isLoading = false;
      return;
    }
    const formData = new FormData();

    // Control data fields quantityType, packaging and origin are not empty. Elese error and display setErrorText

    Object.keys(productData).forEach((key) => {
      formData.append(key, productData[key]);
    });
    if (this.selectedImage) {
      formData.append('imageFile', this.selectedImage, this.selectedImage.name);
    }

    if (this.productFromDb != null && this.productFromDb.id > 0) {
      this.isLoading = true;
      try {
        const observer = {
          next: (product: Product) => {
            // Handle the successful response here
            console.log('Product:', product);

            // Assign the received product to your variable if needed
            this.productFromDb = product;
            this.product = product;
            this.isLoading = false;
            let error = false;

            if (this.product.quantityType == null) {
              this.product.quantityType = '';
              this.setErrorText('Le Type de quantité est obligatoire');
              error = true;
            }
            if (this.product.packaging == null) {
              this.product.packaging = '';
              this.setErrorText('Le Conditionnement est obligatoire');
              error = true;
            }
            if (this.product.origin == null) {
              this.product.origin = '';
              this.setErrorText("L'origine est obligatoire");
              error = true;
            }
            // Afficher le message de succès pendant 5 secondes
            if (error == false) {
              this.setSuccessText(
                'Le produit ' + this.product.name + ' a été mis à jour'
              );
            }
          },
          error: (error: HttpErrorResponse) => {
            // Handle errors here
            if (error.error instanceof ErrorEvent) {
              // Client-side error
              this.setErrorText(error.error.message);
            } else {
              // Server-side error
              console.log(error);
              if (error.error) {
                this.setErrorText(error.error);
              } else {
                this.setErrorText(error.message);
              }
            }
          },
        };

        this.productsService.updateProduct(formData, observer);
      } catch (error) {
        // Handle the caught error
        this.setErrorText(error.message);
        // You can perform error handling or show an error message here
      }
      this.isLoading = false;
    } else {
      this.isLoading = true;
      try {
        this.product.id = 0;

        const observer = {
          next: (product: Product) => {
            console.log('Product:', product);

            this.productFromDb = product;
            this.product = product;
            this.reactiveForm.patchValue({ id: product.id });
            this.reactiveForm.patchValue({
              creationDate: product.creationDate,
            });
            this.reactiveForm.patchValue({
              image: product.image,
            });

            if (this.product.quantityType == null) {
              this.product.quantityType = '';
            }
            if (this.product.packaging == null) {
              this.product.packaging = '';
            }
            if (this.product.origin == null) {
              this.product.origin = '';
            }
            this.isLoading = false;
            // Afficher le message de succès pendant 5 secondes
            this.setSuccessText(
              'Le produit ' + this.product.name + ' a été créé'
            );
          },
          error: (error: HttpErrorResponse) => {
            console.log('ERREUR createProduct');
            console.log(error);
            // Handle errors here
            if (error.error instanceof ErrorEvent) {
              // Client-side error
              this.setErrorText(error.error.message);
            } else {
              // Server-side error
              this.setErrorText(error.error.message);
            }
          },
        };
        this.productsService.createProduct(formData, observer);
      } catch (error) {
        // Handle the caught error
        console.error('An error occurred:', error);
        this.errorText = error.message;
        // You can perform error handling or show an error message here
      }

      this.isLoading = false;
    }
  }

  private generateProductFormGroup(product: Product) {
    return this.fb.group({
      status: this.fb.control({ value: product.status, disabled: false }, []),
      id: this.fb.control({ value: product.id, disabled: false }, []),
      creationDate: this.fb.control(
        { value: product.creationDate, disabled: false },
        []
      ),
      category: this.fb.control(
        { value: product.category, disabled: false },
        []
      ),
      quantityType: this.fb.control(
        { value: product.quantityType, disabled: false },
        []
      ),
      name: this.fb.control(
        {
          value: product.name,
          disabled: false,
        },
        []
      ),
      shortName: this.fb.control(
        {
          value: product.shortName,
          disabled: false,
        },
        []
      ),
      position: this.fb.control(
        {
          value: product.position,
          disabled: false,
        },
        []
      ),
      packaging: this.fb.control(
        {
          value: product.packaging,
          disabled: false,
        },
        []
      ),
      origin: this.fb.control(
        {
          value: product.origin,
          disabled: false,
        },
        []
      ),
      angus: this.fb.control(
        {
          value: product.angus,
          disabled: false,
        },
        []
      ),
      opportunity: this.fb.control(
        {
          value: product.opportunity,
          disabled: false,
        },
        []
      ),
      isInBdc: this.fb.control(
        {
          value: product.isInBdc,
          disabled: false,
        },
        []
      ),
      image: this.fb.control(
        {
          value: product.image,
          disabled: false,
        },
        []
      ),
    });
  }
}
