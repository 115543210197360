import { Injectable } from '@angular/core';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ApiResult } from '../ApiResult';
import { environment } from '../../../environments/environment';
import { DocumentNote } from '../DocumentNote';
import { DocumentFilter } from '../Filters';

@Injectable({ providedIn: 'root' })
export class DocumentsService {
  private documentsUrl = environment.apiUrl + '/documents';
  private documentsDeleteFileUrl = environment.apiUrl + '/DeleteDocumentFile';
  private pdfForDocumentUrl = environment.apiUrl + '/GetPdfForDocument';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient, public router: Router) {}

  getDocuments(
    page: number,
    pageSize: number,
    documentFilter: DocumentFilter
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());

    if (documentFilter.name) {
      params = params.set('name', documentFilter.name);
    }
    if (documentFilter.type) {
      params = params.set('type', documentFilter.type);
    }
    if (
      documentFilter.creationDate &&
      !isNaN(documentFilter.creationDate.getTime())
    ) {
      console.log('documentFilter.creationDate', documentFilter.creationDate);
      params = params.set(
        'creationDate',
        documentFilter.creationDate.toISOString()
      );
    }

    return this.http.get<any>(this.documentsUrl, { params }).pipe(
      map((data) => {
        return {
          documents: data.documents.map((doc) => ({
            ...doc,
            creationDate: new Date(doc.creationDate),
            inputDate: new Date(doc.inputDate),
            arrivalDate: new Date(doc.arrivalDate),
          })),
          totalDocuments: data.totalDocuments,
        };
      })
    );
  }

  getDocument(idDocument: string): Observable<DocumentNote> {
    console.log('products service getDocument');
    return this.http
      .get<DocumentNote>(this.documentsUrl + '/' + idDocument)
      .pipe(tap((_) => console.log('fetched Document')));
  }

  updateDocument(formData: FormData, observer) {
    console.log('products service updateDocument');
    formData.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });
    return this.http
      .patch<DocumentNote>(this.documentsUrl, formData)
      .subscribe(observer);
  }

  createDocument(formData: FormData, observer) {
    this.http
      .post<DocumentNote>(this.documentsUrl, formData)
      .subscribe(observer);
  }

  deleteDocument(document: DocumentNote, observer) {
    return this.http
      .delete<any>(this.documentsUrl + '/' + document.id)
      .subscribe(observer);
  }

  uploadImage(selectedFile: File): Observable<any> {
    const formData: FormData = new FormData();
    if (selectedFile) {
      formData.append('image', selectedFile, selectedFile.name);
    }

    return this.http.post<any>(this.documentsUrl, formData);
  }

  deleteFile(document: DocumentNote, observer) {
    return this.http
      .delete<any>(this.documentsDeleteFileUrl + '/' + document.id)
      .subscribe(observer);
  }

  getPdf(documentId: number, observer) {
    return this.http
      .get(this.pdfForDocumentUrl + '/' + documentId, {
        responseType: 'blob' as 'json',
      })
      .subscribe(observer);
  }
}
