export class Product {
  id: number;
  creationDate: Date;
  name: string;
  shortName: string;
  position: number;
  category: string;
  status: number;
  quantityType: string;
  packaging: string;
  origin: string;
  angus: boolean;
  opportunity: boolean;
  isInBdc: boolean;
  image: string;
  commandLines: any[];
  stocks: any[];

  constructor(init?: Partial<Product>) {
    Object.assign(this, init);
  }
}
