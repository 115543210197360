import { Injectable } from '@angular/core';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { User } from '../User';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Society } from '../Society';
import { ApiResult } from '../ApiResult';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class HelpersService {
  getQuantityTypeLabel(quantityLetter: string, quantity: number = 1) {
    let message: string;

    switch (quantityLetter) {
      case 'C':
        message = 'Carton';
        if (quantity > 1) {
          message += 's';
        }
        break;
      case 'K':
        message = 'Kg';
        break;
      case 'P':
        message = 'Pièce';
        if (quantity > 1) {
          message += 's';
        }
        break;
      default:
        message = '';
        break;
    }

    return message;
  }

  getOriginLabel(originLetter: string) {
    let message: string;

    switch (originLetter) {
      case 'F':
        message = 'FR';
        break;
      case 'E':
        message = 'EU';
        break;
      case 'B':
        message = 'B/B';
        break;
      default:
        message = '';
        break;
    }

    return message;
  }

  getCategoryLabel(categoryLetter: string | undefined) {
    let category: string;

    switch (categoryLetter) {
      case 'B':
        category = 'Bœuf';
        break;
      case 'P':
        category = 'Porc';
        break;
      case 'V':
        category = 'Veau';
        break;
      case 'A':
        category = 'Agneau';
        break;
      case 'O':
        category = 'Autre';
        break;
      case 'G':
        category = 'Angus';
        break;
      default:
        category = 'Autre';
        break;
    }

    return category;
  }

  getPackagingLabel(packagingLetter: string) {
    let message: string;

    switch (packagingLetter) {
      case 'F':
        message = 'Frais';
        break;
      case 'S':
        message = 'Sous-Vide';
        break;
      default:
        message = '';
        break;
    }

    return message;
  }

  isNotEmpty(value): boolean {
    return value != null && value != undefined && value != '';
  }

  getDocumentTypeLabel(documentTypeLetter: string) {
    let message: string;

    switch (documentTypeLetter) {
      case 'A':
        message = "Demande d'avoir";
        break;
      case 'L':
        message = 'Litige';
        break;
      case 'R':
        message = 'Retour Marchandise';
        break;
      case 'B':
        message = 'Bon de livraison';
        break;
      case 'N':
        message = 'Note';
        break;
      default:
        message = '';
        break;
    }

    return message;
  }

  emptyStringToNull(value: any): any {
    return value === '' ? null : value;
  }
}
