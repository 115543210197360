import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ClientPanelComponent } from '../client-panel/client-panel.component';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/dao/services/users.service';
import { Society } from 'src/app/dao/Society';
import { SocietiesService } from 'src/app/dao/services/societies.service';
import { OrderCategoryBlockComponent } from '../order-category-block/order-category-block.component';
import { FeatherModule } from 'angular-feather';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { Product } from 'src/app/dao/Product';
import { ProductsService } from 'src/app/dao/services/products.service';
import { CommonModule } from '@angular/common';
import { OrdersService } from 'src/app/dao/services/orders.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { HelpersService } from 'src/app/dao/services/helpers.service';
import { Order } from 'src/app/dao/Order';
import { HttpErrorResponse } from '@angular/common/http';
import { OrderLine } from 'src/app/dao/OrderLine';

@Component({
  selector: 'app-order-details',
  standalone: true,
  templateUrl: './order-details.component.html',
  styleUrl: './order-details.component.scss',
  imports: [
    ClientPanelComponent,
    OrderCategoryBlockComponent,
    CommonModule,
    ReactiveFormsModule,
    FeatherModule,
  ],
  animations: [
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: 0,
        })
      ),
      transition('void <=> *', animate(500)),
    ]),
  ],
})
export class OrderDetailsComponent implements OnInit {
  orderId: string | null = null;
  societyId: string | null = null;
  products: Product[] = null;
  society: Society;
  clientComment: string;
  parentFormGroup: FormGroup;
  validationError = false;
  errorText: string = '';
  successText: string = '';
  emptyForm: boolean = false;
  isLoading: boolean = false;
  currentOrder: Order = null;
  orderDetails: boolean = true;
  categoryClass: string = 'col-xl-6 col-12 pb-3';
  editMode: boolean = false;

  @ViewChildren(OrderCategoryBlockComponent)
  categoryBlocks: QueryList<OrderCategoryBlockComponent>;

  constructor(
    private route: ActivatedRoute,
    private societiesService: SocietiesService,
    private productsService: ProductsService,
    private ordersService: OrdersService,
    private helpersService: HelpersService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.orderId = params['orderId'] ? params['orderId'] : null;
      this.societyId = params['societyId'] ? params['societyId'] : null;

      this.societiesService.getSociety(this.societyId).subscribe((society) => {
        this.society = society;
      });
      this.productsService.getProducts().subscribe((products) => {
        this.products = products;
      });
      console.log('this.orderId', this.orderId);
      if (
        this.orderId != null &&
        this.orderId != undefined &&
        this.orderId != '0'
      ) {
        this.ordersService.getOrder(this.orderId).subscribe((order) => {
          this.currentOrder = order;
          this.clientComment = order.clientComment;
          this.initializeData(order);
        });
      }
      /*
      this.ordersService.getLastOrderId().subscribe((orderId) => {
        this.orderId = orderId.toString();
      });
      */
    });
    this.parentFormGroup = this.fb.group({
      // Initialize the parent form group, possibly with empty child form groups initially
    });
    this.parentFormGroup.addControl('comments', new FormControl(''));
  }

  private initializeData(order: Order) {
    console.log('initializeData');
    console.log(order);

    if (this.parentFormGroup.contains('comments')) {
      this.parentFormGroup.get('comments').patchValue(order.comment);
    }
  }

  onFormGroupReady(
    formGroupMap: { [key: string]: FormGroup },
    category: string
  ): void {
    // Link the child form group to the parent form group under the category key
    const categoryFormGroup = new FormGroup(formGroupMap);
    this.parentFormGroup.setControl(category, categoryFormGroup);
  }

  public deleteMessages() {
    this.successText = '';
    this.errorText = '';
    this.validationError = false;
  }

  private setSuccessText(message: string) {
    this.successText = message;

    setTimeout(() => {
      this.successText = ''; // Retirer le message après 5 secondes
    }, 5000);
  }

  private setErrorText(message: string) {
    this.errorText = message;
    /*
    setTimeout(() => {
      this.successText = ''; // Retirer le message après 5 secondes
    }, 5000);

    */
  }

  activateEditMode() {
    this.editMode = true;
    this.categoryBlocks.forEach((categoryBlock) => {
      categoryBlock.editMode = true;
    });
  }

  validateAllForms(treatOrder: boolean, selectValue?: string): boolean {
    let isValid = true;
    this.emptyForm = true;
    this.validationError = false;

    this.setErrorText('');
    this.setSuccessText('');

    Object.keys(this.parentFormGroup.controls).forEach((key) => {
      const childFormGroup = this.parentFormGroup.get(key) as FormGroup;

      if (this.shouldSetErrorForGroup(childFormGroup)) {
        childFormGroup.setErrors({ customError: true });
        isValid = false;
      } else {
        childFormGroup.setErrors(null);
      }
    });
    if (this.emptyForm) {
      isValid = false;
      this.errorText = 'La commande est vide';
    }
    console.log('IS VALID : ' + isValid);
    console.log('this.validationError : ' + this.validationError);
    if (this.validationError) {
      return false;
    }

    if (isValid) {
      this.isLoading = true;

      try {
        const observer = {
          next: (order: Order) => {
            this.isLoading = false;
            console.log('Order:', order);

            this.currentOrder = order;
            this.orderId = this.currentOrder.id.toString();

            this.setSuccessText(
              'La commande ' + this.orderId + ' a été enregistrée'
            );
          },
          error: (error: HttpErrorResponse) => {
            this.isLoading = false;
            console.log('ERREUR createOrder');
            console.log(error);
            // Handle errors here
            if (error.error instanceof ErrorEvent) {
              // Client-side error
              this.setErrorText(error.error.message);
            } else {
              // Server-side error
              this.setErrorText(error.message);
            }
          },
        };

        let createdOrder = this.createOrder(treatOrder);

        console.log('Created order', createdOrder);
        this.isLoading = false;

        if (this.currentOrder == null) {
          this.ordersService.createOrder(createdOrder, observer);
        } else {
          createdOrder.id = this.currentOrder.id;
          createdOrder.creationDate = this.currentOrder.creationDate;
          this.ordersService.modifyOrder(createdOrder, observer);
        }
      } catch (error) {
        // Handle the caught error
        console.error('An error occurred:', error);
        this.errorText = error.message;
      }
    }

    return isValid;
  }

  createOrder(treatOrder: boolean): Order {
    const commentsControl = this.parentFormGroup.get('comments');

    let createdOrder: Order = {
      id: 0,
      creationDate: new Date(),
      societyId: parseInt(this.societyId),
      clientId: this.society.client.id,

      status: treatOrder ? 'T' : 'B',
      orderLines: [],
      comment: commentsControl.value,
    };

    Object.keys(this.parentFormGroup.controls).forEach((key) => {
      const firstChildFormGroup = this.parentFormGroup.get(key) as FormGroup;
      if (firstChildFormGroup && firstChildFormGroup.controls) {
        Object.keys(firstChildFormGroup.controls).forEach((key) => {
          const childFormGroup = firstChildFormGroup.get(key) as FormGroup;
          /*
          const isLineFullyFilled =
            this.helpersService.isNotEmpty(
              childFormGroup.get('lotNumber')?.value
            ) &&
            this.helpersService.isNotEmpty(
              childFormGroup.get('quantity')?.value
            ) &&
            this.helpersService.isNotEmpty(
              childFormGroup.get('weight')?.value
            ) &&
            this.helpersService.isNotEmpty(childFormGroup.get('price')?.value);
*/

          const isLineFullyFilled = this.helpersService.isNotEmpty(
            childFormGroup.get('quantity')?.value
          );

          if (
            isLineFullyFilled &&
            !childFormGroup.get('isRemoved')?.value == true
          ) {
            console.log('childFormGroup');
            console.log(childFormGroup);
            const orderLine: OrderLine = {
              id: childFormGroup.get('id')?.value ?? 0,
              lotNumber: this.helpersService.emptyStringToNull(
                childFormGroup.get('lotNumber')?.value
              ),
              price: this.helpersService.emptyStringToNull(
                childFormGroup.get('price')?.value
              ),
              weight: this.helpersService.emptyStringToNull(
                childFormGroup.get('weight')?.value
              ),
              quantity:
                childFormGroup.get('quantity')?.value == 'X'
                  ? -1
                  : childFormGroup.get('quantity')?.value,
              quantityType:
                childFormGroup.get('quantityType')?.value == ''
                  ? null
                  : childFormGroup.get('quantityType')?.value,
              origin:
                childFormGroup.get('origin')?.value == ''
                  ? null
                  : childFormGroup.get('origin')?.value,
              packaging:
                childFormGroup.get('packaging')?.value == ''
                  ? null
                  : childFormGroup.get('packaging')?.value,
              product: childFormGroup.controls['product'].value,
              erasable: false,
              editable: true,
              formId: '0',
              isRemoved: false,
              sortOrder: 0,
            };
            createdOrder.orderLines.push(orderLine);
          }
        });
      }
    });

    return createdOrder;
  }

  shouldSetErrorForGroup(formGroup: FormGroup): boolean {
    let hasError = false;

    if (formGroup && formGroup.controls) {
      Object.keys(formGroup.controls).forEach((key) => {
        const childFormGroup = formGroup.get(key) as FormGroup;
        if (!childFormGroup.get('isRemoved')?.value == true) {
          let isLinePartiallyFilled: boolean = false;
          let isLineFullyFilled: boolean = false;
          isLinePartiallyFilled =
            this.helpersService.isNotEmpty(
              childFormGroup.get('lotNumber')?.value
            ) ||
            this.helpersService.isNotEmpty(
              childFormGroup.get('quantity')?.value
            ) ||
            this.helpersService.isNotEmpty(
              childFormGroup.get('weight')?.value
            ) ||
            this.helpersService.isNotEmpty(
              childFormGroup.get('price')?.value
            ) ||
            this.helpersService.isNotEmpty(
              childFormGroup.get('quantityType')?.value
            ) ||
            this.helpersService.isNotEmpty(
              childFormGroup.get('packaging')?.value
            ) ||
            this.helpersService.isNotEmpty(childFormGroup.get('origin')?.value);
          /*
          isLineFullyFilled =
            this.helpersService.isNotEmpty(
              childFormGroup.get('lotNumber')?.value
            ) &&
            this.helpersService.isNotEmpty(
              childFormGroup.get('quantity')?.value
            ) &&
            this.helpersService.isNotEmpty(
              childFormGroup.get('weight')?.value
            ) &&
            this.helpersService.isNotEmpty(childFormGroup.get('price')?.value);
*/

          isLineFullyFilled = this.helpersService.isNotEmpty(
            childFormGroup.get('quantity')?.value
          );

          if (isLinePartiallyFilled) {
            console.log('ICI isLinePartiallyFilled');
            this.emptyForm = false;
          }
          if (isLinePartiallyFilled && !isLineFullyFilled) {
            /*
            this.checkEmpty(
              childFormGroup,
              'lotNumber',
              'Le numéro de Lot doit être saisi'
            
            */

            this.checkEmpty(
              childFormGroup,
              'quantity',
              'La quantité doit être saisie'
            );

            /*
            this.checkEmpty(
              childFormGroup,
              'price',
              'Le poids doit être saisi'
            );
            this.checkEmpty(
              childFormGroup,
              'weight',
              'Le prix doit être saisi'
            );
*/
            this.validateField(childFormGroup, 'quantity');
            this.validateField(childFormGroup, 'weight', true);
            this.validateField(childFormGroup, 'price', true);
          }
        }
      });
    }

    return hasError;
  }

  checkEmpty(childFormGroup: FormGroup, fieldName: string, error: string) {
    console.log('checkEmpty ' + fieldName);
    if (!this.helpersService.isNotEmpty(childFormGroup.get(fieldName)?.value)) {
      childFormGroup.get(fieldName)?.setErrors({ customError: error });
      childFormGroup.get(fieldName)?.markAllAsTouched();
      this.validationError = true;
      console.log('    Vlidation error');
    } else {
      console.log('    OK');
    }
  }

  validateField(
    childFormGroup: FormGroup,
    fieldName: string,
    isFloat: boolean = false
  ) {
    const fieldValue = childFormGroup.get(fieldName)?.value;
    if (fieldValue) {
      const value = isFloat ? parseFloat(fieldValue) : parseInt(fieldValue, 10);
      if (
        isNaN(value) ||
        value <= 0 ||
        value >= 9999 ||
        (isFloat && !/^\d+(\.\d{0,2})?$/.test(fieldValue))
      ) {
        childFormGroup
          .get(fieldName)
          ?.setErrors({ customError: `Le champs doit être un nombre` });
        childFormGroup.get(fieldName)?.markAllAsTouched();
        this.validationError = true;
      }
    }
  }
}
