<div class="container-fluid" style="--bs-gutter-x: 0px;">
    <div class="flex-container">
        <div class="flex-row header">
            <div class="flex-col header-row l13p">N° LOT</div>
            <div class="flex-col header-row l16p">QTÉ</div>
            <div class="flex-col header-row l19p category-name">{{helpersService.getCategoryLabel(category)}}</div>
            <div class="flex-col header-row l13p">POIDS</div>
            <div class="flex-col header-row l13p">PRIX</div>
        </div>

        <div *ngFor="let group of orderLineFormGroups | keyvalue: keyAscComparator; let isLast=last;"
            [class.angus-row]="group.value.controls['product'].value.angus && group.value.controls['product'].value.category == 'B'">
            <ng-container *ngIf="!group.value.get('isRemoved')?.value">
                <form [formGroup]="group.value" class="form-flex">
                    <div class="flex-row" [class.last-row]="isLast && numberOfEmptyRows <= 0">
                        <div class="flex-col l13p">{{group.value.controls['lotNumber'].value}}</div>
                        <div class="flex-col l16p">{{group.value.controls['quantity'].value}}
                            {{helpersService.getQuantityTypeLabel(group.value.controls['quantityType'].value,
                            group.value.controls['quantity'].value)}}</div>

                        <div class="flex-col product-name l19p">{{ group.value.controls['product'].value.shortName ?
                            group.value.controls['product'].value.shortName : group.value.controls['product'].value.name
                            }}</div>
                        <div class="flex-col l13p">{{ group.value.controls['weight'].value == 0 ? "" :
                            group.value.controls['weight'].value}}</div>
                        <div class="flex-col l13p">{{ group.value.controls['price'].value == 0 ? "" :
                            group.value.controls['price'].value}}</div>
                    </div>
                </form>
            </ng-container>
        </div>

        <!-- Add configurable number of empty rows at the bottom of the table -->
        <div *ngFor="let i of [].constructor(numberOfEmptyRows) ; let isLast=last;" class="empty-row">
            <div class="flex-row" [class.last-row]="isLast ">
                <div class="flex-col l13p">&nbsp;</div>
                <div class="flex-col l16p">&nbsp;</div>
                <div class="flex-col l19p">&nbsp;</div>
                <div class="flex-col l13p">&nbsp;</div>
                <div class="flex-col l13p">&nbsp;</div>
            </div>
        </div>
    </div>
</div>