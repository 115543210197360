import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { OrderFilter } from '../../dao/Filters';

@Component({
  selector: 'app-choose-order-filter-panel',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './choose-order-filter-panel.component.html',
  styleUrl: './choose-order-filter-panel.component.scss',
})
export class ChooseOrderFilterPanelComponent {
  @Output() closeFilterPanel = new EventEmitter<void>(); // Emit an event without any value
  @Output() filterValueChanged = new EventEmitter<OrderFilter>();
  filterIdControl = new FormControl('');

  constructor(private router: Router) {
    this.initializeValueChanges();
  }

  @Input() showFilterPanel = false;
  societies: any[] = [];
  filterId: number = 0;
  filterStatus: string = '';
  filterDate: string = '';
  isFadingOut: boolean;

  initializeValueChanges() {
    this.filterIdControl.valueChanges
      .pipe(
        debounceTime(500), // Wait for 500ms of inactivity
        distinctUntilChanged() // Emit only if the value has changed
      )
      .subscribe((value) => {
        const numericValue = +value; // Convert string to number
        if (!isNaN(numericValue)) {
          this.filterId = numericValue;
          this.emitFilterChange();
        }
      });
  }

  emitFilterChange() {
    console.log('EMIT FILTER CHANGE');
    let nbFilters = 0;
    if (this.filterId > 0) {
      nbFilters++;
    }
    if (this.filterStatus != undefined && this.filterStatus != '') {
      nbFilters++;
    }
    if (this.filterDate != undefined && this.filterDate != '') {
      nbFilters++;
    }
    this.filterValueChanged.emit({
      nbFilters: nbFilters,
      id: this.filterId,
      status: this.filterStatus,
      date: new Date(this.filterDate), // Assuming filterDate is in a compatible format
    });
  }

  cancel() {
    this.isFadingOut = true;

    // Wait for the animation to complete before hiding the panel
    setTimeout(() => {
      this.showFilterPanel = false;
      this.isFadingOut = false;
    }, 500);
    this.closeFilterPanel.emit(); // When cancel is called, emit the close event
  }

  removeFilters() {
    this.filterId = 0;
    this.filterStatus = '';
    this.filterDate = '';
    this.filterIdControl.reset('');
    this.emitFilterChange();
  }
}
