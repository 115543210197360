import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, KeyValue } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Product } from 'src/app/dao/Product';
import { HelpersService } from 'src/app/dao/services/helpers.service';
import { SocietiesService } from 'src/app/dao/services/societies.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { OrderLine } from 'src/app/dao/OrderLine';
import { ChangeDetectorRef } from '@angular/core';
import { Order } from 'src/app/dao/Order';

@Component({
  selector: 'app-order-category-block',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './order-category-block.component.html',
  styleUrls: ['./order-category-block.component.scss'],
})
export class OrderCategoryBlockComponent implements OnInit {
  @Input() allProducts: Product[];
  @Input() category: string;
  @Input() newOrder: boolean;
  @Input() currentOrder: Order;
  @Input() orderDetails: boolean;
  @Input() editMode: boolean;
  @Input() numberOfEmptyRows: number = 0;

  @Output() formGroupEmitter = new EventEmitter<{ [key: string]: FormGroup }>();
  focusedRow: number | null = null;
  orderLines: OrderLine[] = [];
  orderLineFormGroups: { [key: string]: FormGroup } = {};
  searchResults: Product[] = [];
  showResults: boolean = false;
  searchText: string = '';
  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(
    private fb: FormBuilder,
    public helpersService: HelpersService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.allProducts = this.allProducts.filter(
      (p) => p.category == this.category
    );
    this.createFormGroups();
  }

  keyAscComparator(a: KeyValue<string, any>, b: KeyValue<string, any>): number {
    return parseInt(a.key, 10) - parseInt(b.key, 10);
  }

  createFormGroups() {
    this.orderLines = [];
    let index = 0;

    if (this.currentOrder == null) {
      this.allProducts
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach((product) => {
          // Base timestamp (e.g., January 1, 2024, in milliseconds)
          const baseTimestamp = new Date('2024-01-01T00:00:00Z').getTime();

          // Calculate the time since base in milliseconds
          let uniqueId = Date.now() - baseTimestamp;

          // Ensure the uniqueId is within the 32-bit integer range
          uniqueId =
            (uniqueId % 2147483647) * 100 + Math.floor(Math.random() * 1000);
          uniqueId = uniqueId % 100000000;
          if (product.isInBdc) {
            this.orderLines.push({
              id: uniqueId,
              formId: '0',
              lotNumber: '',
              quantity: '',
              quantityType: '',
              origin: '',
              packaging: '',
              weight: '',
              price: '',
              product: product,
              erasable: false,
              editable: true,
              isRemoved: false,
              sortOrder: 0,
            });
          }
        });
    } else {
      let remainingOrderLines = [...this.currentOrder.orderLines];
      this.allProducts
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach((product) => {
          if (product.isInBdc) {
            let foundIndex = remainingOrderLines.findIndex(
              (c) => c.product.id == product.id
            );
            if (foundIndex > -1) {
              let foundOrderLine = remainingOrderLines[foundIndex];
              this.orderLines.push({
                id: foundOrderLine.id,
                formId: this.category + index++,
                lotNumber: foundOrderLine.lotNumber,
                quantity: foundOrderLine.quantity,
                quantityType: foundOrderLine.quantityType,
                origin: foundOrderLine.origin,
                packaging: foundOrderLine.packaging,
                weight: foundOrderLine.weight,
                price: foundOrderLine.price,
                product: product,
                erasable: false,
                editable: true,
                isRemoved: false,
                sortOrder: 0,
              });
              remainingOrderLines.splice(foundIndex, 1);
            } else {
              // Base timestamp (e.g., January 1, 2024, in milliseconds)
              const baseTimestamp = new Date('2024-01-01T00:00:00Z').getTime();

              // Calculate the time since base in milliseconds
              let uniqueId = Date.now() - baseTimestamp;

              // Ensure the uniqueId is within the 32-bit integer range
              uniqueId =
                (uniqueId % 2147483647) * 100 +
                Math.floor(Math.random() * 1000);
              uniqueId = uniqueId % 100000000;
              this.orderLines.push({
                id: uniqueId,
                formId: '0',
                lotNumber: '',
                quantity: '',
                quantityType: '',
                origin: '',
                packaging: '',
                weight: '',
                price: '',
                product: product,
                erasable: false,
                editable: false,
                isRemoved: false,
                sortOrder: 0,
              });
            }
          }
        });

      remainingOrderLines.forEach((orderLine) => {
        if (orderLine.product.category == this.category) {
          this.orderLines.push({
            id: orderLine.id,
            formId: this.category + index++,
            lotNumber: orderLine.lotNumber,
            quantity: orderLine.quantity,
            quantityType: orderLine.quantityType,
            origin: orderLine.origin,
            packaging: orderLine.packaging,
            weight: orderLine.weight,
            price: orderLine.price,
            product: orderLine.product,
            erasable: true,
            editable: true,
            isRemoved: false,
            sortOrder: 0,
          });
        }
      });
    }

    this.convertOrderLinesToOrderLinesFormGroup();
    this.cdr.detectChanges();
    this.emitFormGroup();
  }

  convertOrderLinesToOrderLinesFormGroup() {
    this.orderLines = this.orderLines.sort((a, b) => {
      if (a.sortOrder === b.sortOrder) {
        const nameComparison = a.product.name.localeCompare(b.product.name);
        if (nameComparison === 0) {
          return a.id.toString().localeCompare(b.id.toString());
        }
        return nameComparison;
      }
      return a.sortOrder - b.sortOrder;
    });

    const newOrderLineFormGroups = {};

    for (let i = 0; i < this.orderLines.length; i++) {
      const orderLine = this.orderLines[i];

      // Always create a new form group to avoid sharing references
      newOrderLineFormGroups[i] = this.fb.group({
        id: orderLine.id,
        formId: this.category + i,
        lotNumber: orderLine.lotNumber,
        quantity: orderLine.quantity,
        quantityType: orderLine.quantityType,
        origin: orderLine.origin,
        packaging: orderLine.packaging,
        weight: orderLine.weight == '0' ? '' : orderLine.weight,
        price: orderLine.price == '0' ? '' : orderLine.price,
        product: orderLine.product, // Ensure each form group has its own product reference
        erasable: orderLine.erasable,
        editable: orderLine.editable,
        isRemoved: orderLine.isRemoved,
        sortOrder: orderLine.sortOrder ?? 0,
      });
    }

    this.orderLineFormGroups = newOrderLineFormGroups;
    this.emitFormGroup();
  }

  emitFormGroup() {
    this.formGroupEmitter.emit(this.orderLineFormGroups);
  }

  isEmpty(value: string): boolean {
    return value === null || value === '';
  }

  isClosed(): boolean {
    if (this.newOrder || this.currentOrder == undefined) {
      return false;
    } else {
      return this.currentOrder.status == 'T';
    }
  }

  isActive(formGroup: FormGroup): boolean {
    return (
      this.newOrder ||
      this.editMode ||
      this.helpersService.isNotEmpty(formGroup.get('lotNumber').value) ||
      this.helpersService.isNotEmpty(formGroup.get('quantity').value) ||
      this.helpersService.isNotEmpty(formGroup.get('quantityType').value) ||
      this.helpersService.isNotEmpty(formGroup.get('origin').value) ||
      this.helpersService.isNotEmpty(formGroup.get('packaging').value) ||
      this.helpersService.isNotEmpty(formGroup.get('weight').value) ||
      this.helpersService.isNotEmpty(formGroup.get('price').value)
    );
  }

  isEditable(formGroup: FormGroup): boolean {
    let ret = this.newOrder || formGroup.get('editable').value !== false;
    if (!this.isLineEmpty(formGroup)) ret = true;
    if (this.isLineEmpty(formGroup) && !this.editMode && !this.newOrder)
      ret = false;
    return ret || this.editMode;
  }

  isDuplicable(formGroup: FormGroup): boolean {
    return !this.isLineEmpty(formGroup);
  }

  isDeletable(formGroup: FormGroup): boolean {
    const sortOrder = formGroup.get('sortOrder')?.value || 0;
    return !this.isLineEmpty(formGroup) || sortOrder !== 0;
  }

  isLineEmpty(formGroup: FormGroup): boolean {
    return !(
      this.helpersService.isNotEmpty(formGroup.get('lotNumber').value) ||
      this.helpersService.isNotEmpty(formGroup.get('quantity').value) ||
      this.helpersService.isNotEmpty(formGroup.get('quantityType').value) ||
      this.helpersService.isNotEmpty(formGroup.get('origin').value) ||
      this.helpersService.isNotEmpty(formGroup.get('packaging').value) ||
      this.helpersService.isNotEmpty(formGroup.get('weight').value) ||
      this.helpersService.isNotEmpty(formGroup.get('price').value)
    );
  }

  onInputChange(event: Event): void {
    // Handle input change if necessary
  }

  handleFocus(event: FocusEvent, index: number): void {
    if (this.isClosed()) {
      (event.target as HTMLElement).blur();
    } else {
      this.setFocusedRow(index);
    }
  }

  restrictInput(event: any, index: number): void {
    const input = event.target.value;

    // Define the regular expression for valid input (numbers, X, x, and commas)
    const regex = /^(X|x|\d+[0-9,]*)$/;

    // Get the form control associated with this input
    const formControl = this.orderLineFormGroups[index].get('quantity');

    // Check if the input matches the regex pattern
    if (!regex.test(input)) {
      console.log('NOT MATCH');

      // If the input doesn't match, remove the last entered character
      const validInput = input.slice(0, -1);
      formControl.setValue(validInput); // Update the form control's value
    } else {
      // If valid input, update the form control accordingly
      if (input === 'X' || input === 'x') {
        formControl.setValue(-1); // If "X" or "x", set quantity to -1
      } else {
        formControl.setValue(input); // Otherwise, set the input value as is
      }
    }
  }

  // When rendering the value, if the quantity is -1, display "X"
  getDisplayQuantity(quantity: any): string {
    return quantity === -1 ? 'X' : quantity;
  }

  isOptionAvailable(optionsList: string, option: string): boolean {
    return optionsList.includes(option);
  }

  setFocusedRow(index: number): void {
    this.focusedRow = index;
  }

  isFocusedRow(index: number): boolean {
    return this.focusedRow === index;
  }

  clearFocusedRow(): void {
    this.focusedRow = null;
  }

  emptyLine(formGroup: FormGroup) {
    const id: number = formGroup.get('id').value;
    const productId: number = formGroup.get('product').value.id;

    // Find the index of the current line
    const index = this.orderLines.findIndex((orderLine) => orderLine.id === id);

    if (index !== -1) {
      if (this.orderLines[index].erasable !== true) {
        // Find another line with the same product
        const duplicateIndex = this.orderLines.findIndex(
          (orderLine) =>
            orderLine.product.id === productId && orderLine.id !== id
        );

        if (duplicateIndex !== -1) {
          // If another line with the same product is found, copy its content to the non-erasable line
          const duplicateLine = this.orderLines[duplicateIndex];

          // Copy content from the duplicate line to the non-erasable line
          this.orderLines[index] = {
            ...this.orderLines[index],
            lotNumber: duplicateLine.lotNumber,
            quantity: duplicateLine.quantity,
            quantityType: duplicateLine.quantityType,
            origin: duplicateLine.origin,
            packaging: duplicateLine.packaging,
            weight: duplicateLine.weight,
            price: duplicateLine.price,
          };

          // Update the form group with the copied content
          formGroup.patchValue({
            lotNumber: duplicateLine.lotNumber,
            quantity: duplicateLine.quantity,
            quantityType: duplicateLine.quantityType,
            origin: duplicateLine.origin,
            packaging: duplicateLine.packaging,
            weight: duplicateLine.weight,
            price: duplicateLine.price,
          });

          // Remove the duplicate line
          this.orderLines.splice(duplicateIndex, 1);
        } else {
          // If no duplicate found, just clear the non-erasable line's fields
          console.log('Line not erasable. Empty fields');
          this.orderLines[index] = {
            ...this.orderLines[index],
            lotNumber: '',
            quantity: '',
            quantityType: '',
            origin: '',
            packaging: '',
            weight: '',
            price: '',
          };
          formGroup.patchValue({
            lotNumber: '',
            quantity: '',
            quantityType: '',
            origin: '',
            packaging: '',
            weight: '',
            price: '',
          });
        }
      } else {
        // If the line is erasable, remove it
        this.orderLines.splice(index, 1);
      }

      this.convertOrderLinesToOrderLinesFormGroup();
    }
  }

  duplicateProduct(formGroup: FormGroup): void {
    // Ensure that the form group's values are fully updated
    formGroup.updateValueAndValidity();

    // Find the corresponding order line in orderLines using the ID
    const originalOrderLineIndex = this.orderLines.findIndex(
      (line) => line.id === formGroup.get('id')?.value
    );

    if (originalOrderLineIndex !== -1) {
      // Update the orderLines array with the latest formGroup values
      this.orderLines[originalOrderLineIndex] = {
        ...this.orderLines[originalOrderLineIndex],
        ...formGroup.value,
        product: { ...formGroup.value.product }, // Ensure deep clone of the product
      };
      console.log(
        'OrderLines after update:',
        this.orderLines[originalOrderLineIndex]
      );
    } else {
      console.log('No matching OrderLine found in orderLines.');
    }

    // Clone the current values of the form group deeply
    const currentValues = JSON.parse(JSON.stringify(formGroup.value));

    console.log('Duplicating product', currentValues);

    // Base timestamp (e.g., January 1, 2024, in milliseconds)
    const baseTimestamp = new Date('2024-01-01T00:00:00Z').getTime();

    // Calculate the time since base in milliseconds
    let uniqueId = Date.now() - baseTimestamp;

    // Ensure the uniqueId is within the 32-bit integer range
    uniqueId = (uniqueId % 2147483647) * 100 + Math.floor(Math.random() * 1000);
    uniqueId = uniqueId % 100000000;
    // Create a new order line using the captured values
    let orderLine: OrderLine = {
      id: uniqueId,
      lotNumber: currentValues.lotNumber,
      quantity: currentValues.quantity,
      quantityType: currentValues.quantityType,
      origin: currentValues.origin,
      packaging: currentValues.packaging,
      weight: currentValues.weight,
      price: currentValues.price,
      product: { ...currentValues.product }, // Deep clone the product
      erasable: true,
      editable: true,
      formId: '',
      isRemoved: false,
      sortOrder: currentValues.sortOrder,
    };

    // Push the new order line
    this.orderLines.push(orderLine);

    // Explicitly update the original FormGroup with its current values to ensure no data is lost
    formGroup.patchValue(currentValues);

    // Update form groups
    this.convertOrderLinesToOrderLinesFormGroup();
  }

  createLine(product: Product): void {
    this.cdr.detectChanges();

    let maxSortOrder = this.orderLines.reduce(
      (max, ol) => (ol.sortOrder > max ? ol.sortOrder : max),
      0
    );

    // Base timestamp (e.g., January 1, 2024, in milliseconds)
    const baseTimestamp = new Date('2024-01-01T00:00:00Z').getTime();

    // Calculate the time since base in milliseconds
    let uniqueId = Date.now() - baseTimestamp;

    // Ensure the uniqueId is within the 32-bit integer range
    uniqueId = (uniqueId % 2147483647) * 100 + Math.floor(Math.random() * 1000);
    uniqueId = uniqueId % 100000000;

    let orderLine: OrderLine = {
      id: uniqueId,
      lotNumber: '',
      quantity: null,
      quantityType: '',
      origin: '',
      packaging: '',
      weight: null,
      price: null,
      product: product,
      erasable: true,
      editable: true,
      formId: '',
      isRemoved: false,
      sortOrder: maxSortOrder + 1,
    };

    this.orderLines.push(orderLine);
    this.convertOrderLinesToOrderLinesFormGroup();
  }

  onSearch(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.searchText = value;

    if (value) {
      this.searchProducts(value);
      this.showResults = true;
    } else {
      this.searchResults = [];
    }
  }

  searchProducts(searchTerm: string): void {
    const searchTerms = searchTerm.toLowerCase().split(/\s+/);

    this.searchResults = this.allProducts.filter((product) =>
      searchTerms.every((term) => product.name.toLowerCase().includes(term))
    );
  }

  onSelectResult(result: any): void {
    this.showResults = false;
    if (result && 'quantityType' in result) {
      this.createLine(result);
      this.clearSearchInput();
    }
  }

  onInputBlur(): void {
    setTimeout(() => {
      this.showResults = false;
    }, 300);
  }

  clearSearchInput(): void {
    this.searchText = '';
    this.searchResults = [];
    this.showResults = false;
    this.cdr.detectChanges();
    if (this.searchInput && this.searchInput.nativeElement) {
      this.searchInput.nativeElement.value = '';
    }
  }

  onFieldBlur(group: FormGroup, fieldName: string): void {
    // Ensure that the form control is updated
    group.get(fieldName)?.updateValueAndValidity();

    // Retrieve the id and product.id from the form group
    const formGroupId = group.get('id')?.value;
    const formGroupProductId = group.get('product')?.value?.id;

    // Check if the order line already exists in the orderLines array
    const existingOrderLineIndex = this.orderLines.findIndex(
      (line) =>
        line.id === formGroupId && line.product.id === formGroupProductId
    );

    if (existingOrderLineIndex > -1) {
      // If the order line is found, update it with the new values from the form group
      const updatedOrderLine: OrderLine = {
        ...this.orderLines[existingOrderLineIndex],
        lotNumber: group.get('lotNumber')?.value || '',
        quantity: group.get('quantity')?.value || null,
        quantityType: group.get('quantityType')?.value || '',
        origin: group.get('origin')?.value || '',
        packaging: group.get('packaging')?.value || '',
        weight: group.get('weight')?.value || null,
        price: group.get('price')?.value || null,
      };

      // Update the orderLines array with the new values
      this.orderLines[existingOrderLineIndex] = updatedOrderLine;

      console.log(
        `Order line for product ${updatedOrderLine.product.name} with id ${updatedOrderLine.id} updated.`
      );
    } else {
      // If the order line doesn't exist, create a new one
      const product = group.get('product')?.value;

      if (product) {
        // Base timestamp (e.g., January 1, 2024, in milliseconds)
        const baseTimestamp = new Date('2024-01-01T00:00:00Z').getTime();

        // Calculate the time since base in milliseconds
        let uniqueId = Date.now() - baseTimestamp;

        // Ensure the uniqueId is within the 32-bit integer range
        uniqueId =
          (uniqueId % 2147483647) * 100 + Math.floor(Math.random() * 1000);
        uniqueId = uniqueId % 100000000;

        const newOrderLine: OrderLine = {
          id: uniqueId,
          lotNumber: group.get('lotNumber')?.value || '',
          quantity: group.get('quantity')?.value || null,
          quantityType: group.get('quantityType')?.value || '',
          origin: group.get('origin')?.value || '',
          packaging: group.get('packaging')?.value || '',
          weight: group.get('weight')?.value || null,
          price: group.get('price')?.value || null,
          product: product,
          erasable: true,
          editable: true,
          formId: '', // You can set the formId here if needed
          isRemoved: false,
          sortOrder: this.orderLines.length + 1, // or any other logic to determine sortOrder
        };

        // Add the new order line to the orderLines array
        this.orderLines.push(newOrderLine);

        console.log(
          `New order line added for product ${product.name} with id ${uniqueId}.`
        );

        // Re-convert order lines to form groups only if a new line was added
        this.convertOrderLinesToOrderLinesFormGroup();

        // Trigger change detection only if necessary
        this.cdr.detectChanges();
      }
    }

    // Log the updated value
    console.log(
      `Field ${fieldName} in group updated with value:`,
      group.get(fieldName)?.value
    );

    // Additional logic can be added here
  }
}
