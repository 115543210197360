import { Component } from '@angular/core';
import { FeatherModule } from 'angular-feather';

import { OrderCategoryBlockComponent } from '../order-category-block/order-category-block.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ClientPanelComponent } from '../client-panel/client-panel.component';
import { Society } from 'src/app/dao/Society';
import { Product } from 'src/app/dao/Product';
import { Order } from 'src/app/dao/Order';
import { ActivatedRoute } from '@angular/router';
import { SocietiesService } from 'src/app/dao/services/societies.service';
import { ProductsService } from 'src/app/dao/services/products.service';
import { OrdersService } from 'src/app/dao/services/orders.service';
import { HelpersService } from 'src/app/dao/services/helpers.service';
import { OrderCategoryBlockPrintComponent } from '../order-category-block-print/order-category-block-print.component';

@Component({
  selector: 'app-order-print',
  standalone: true,
  imports: [
    ClientPanelComponent,
    OrderCategoryBlockComponent,
    OrderCategoryBlockPrintComponent,
    CommonModule,
    ReactiveFormsModule,
    FeatherModule,
  ],
  templateUrl: './order-print.component.html',
  styleUrl: './order-print.component.scss',
})
export class OrderPrintComponent {
  orderId: string;
  societyId: string;
  errorText: string;
  successText: string;
  validationError: boolean = false;
  society: Society;
  clientComment: string;
  isLoading: boolean = false;
  products: Product[];
  currentOrder: Order;
  parentFormGroup: FormGroup;
  categoryClass: string = 'col-xl-6 col-6 pb-3';

  nbCols: number = 2;
  emptyCols: boolean = true;
  numPage: number;
  totalPage: number;
  displayedCategory: string;

  constructor(
    private route: ActivatedRoute,
    private societiesService: SocietiesService,
    private productsService: ProductsService,
    private ordersService: OrdersService,
    private helpersService: HelpersService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    const resolvedData = this.route.snapshot.data['data'];
    this.society = resolvedData[0];
    this.products = resolvedData[1];
    this.currentOrder = resolvedData[2];
    this.clientComment = this.currentOrder.clientComment;
    this.orderId = this.currentOrder.id.toString();
    this.societyId = this.society.id.toString();

    // Accessing query parameters synchronously

    this.numPage = parseInt(
      this.route.snapshot.queryParamMap.get('numPage') ?? '1'
    );
    this.totalPage = parseInt(
      this.route.snapshot.queryParamMap.get('totalPages') ?? '1'
    );
    this.displayedCategory =
      this.route.snapshot.queryParamMap.get('displayedCategory') ?? 'BPVAOC';

    if (this.nbCols == 1) {
      this.categoryClass = 'col-xl-12 col-12 pb-3';
    }
    console.log('ICI');
  }

  shouldDisplayCategory(category: string): boolean {
    if (
      this.products &&
      this.products.length > 0 &&
      (this.orderId == '0' || this.currentOrder != null)
    ) {
      return this.displayedCategory.includes(category);
    }
    return false;
  }
}
