import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss'],
})
export class FilterButtonComponent {
  @Output() pressed = new EventEmitter<void>(); // Emit an event without any value
  @Input() numberFilters: number = 0;

  pressButton() {
    this.pressed.emit();
  }
}
