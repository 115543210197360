import { Component } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthService } from '../auth.service';
import { LoginResult } from '../dao/LoginResult';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './login.component.html',
  providers: [AuthService],
})
export class LoginComponent {
  msg = '';
  constructor(private authService: AuthService, private routes: Router) {}
  isLoading: boolean = false;
  loginform = true;
  recoverform = false;

  showRecoverForm() {
    this.loginform = !this.loginform;
    this.recoverform = !this.recoverform;
  }
  async check(email: string, password: string) {
    this.isLoading = true;
    var user = await this.authService.login(email, password);
    this.isLoading = false;
    console.log('check');
    console.log(user);
    if (user != null) {
      console.log('navigate clients');
      this.routes.navigate(['/clients']);
    } else {
      this.msg = 'e-mail ou mot de passe invalide';
    }
  }
}
