import { Component, OnInit } from '@angular/core';
import { Stock } from '../dao/Stock';
import { StocksService } from '../dao/services/stocks.service';
import { HelpersService } from '../dao/services/helpers.service';

@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.scss'],
})
export class StocksComponent implements OnInit {
  groupedStocks: {
    category: string;
    products: { name: string; stocks: Stock[]; subtotal: number }[];
  }[] = [];
  stockService: StocksService;
  helpersService: HelpersService;

  constructor(stockService: StocksService, helpersService: HelpersService) {
    this.stockService = stockService;
    this.helpersService = helpersService;
  }

  ngOnInit() {
    this.getStocks();
  }

  getStocks(): void {
    this.stockService.getStocks().subscribe((stocks) => {
      this.groupStocksByCategoryAndProduct(stocks);
    });
  }

  groupStocksByCategoryAndProduct(stocks: Stock[]): void {
    const categoryOrder = ['B', 'V', 'P', 'A', 'O', 'G'];

    const groupedByCategory = stocks.reduce((acc, stock) => {
      let category = stock.product.category;
      if (!acc[category]) {
        acc[category] = {};
      }
      const productName =
        stock.product.shortName != null && stock.product.shortName.trim() != ''
          ? stock.product.shortName
          : stock.product.name;
      if (!acc[category][productName]) {
        acc[category][productName] = { stocks: [], subtotal: 0 };
      }
      acc[category][productName].stocks.push(stock);
      acc[category][productName].subtotal += stock.quantity;
      return acc;
    }, {});

    this.groupedStocks = Object.keys(groupedByCategory)
      .sort((a, b) => {
        const indexA = categoryOrder.indexOf(a);
        const indexB = categoryOrder.indexOf(b);
        return (
          (indexA === -1 ? categoryOrder.length : indexA) -
          (indexB === -1 ? categoryOrder.length : indexB)
        );
      })
      .map((category) => ({
        category,
        products: Object.keys(groupedByCategory[category]).map(
          (productName) => ({
            name: productName,
            stocks: groupedByCategory[category][productName].stocks,
            subtotal: groupedByCategory[category][productName].subtotal,
          })
        ),
      }));

    // Debugging: Log the sorted groupedStocks
    console.log('Grouped and Sorted Stocks:', this.groupedStocks);
  }

  // `trackBy` function for categories
  trackByCategory(index: number, group: { category: string }): string {
    return group.category;
  }

  // `trackBy` function for products
  trackByProduct(index: number, product: { name: string }): string {
    return product.name;
  }

  // `trackBy` function for stocks
  trackByStock(index: number, stock: Stock): string {
    return stock.oa; // Or any unique identifier for the stock
  }
}
