<ul class="navbar-nav float-end">
  <li>
    <a class="navbar-brand" href="/">
      <div class="navbar-content">
        <!-- Logout Button -->
        <button (click)="logout()" class="logout">
          <img src="/assets/images/power-off.png" height="20px" />
        </button>
        <!-- Avatar Image -->
        <div class="avatar-image" id="menuAvatar"
          [style.background-image]="avatarUrl ? 'url(' + avatarUrl + ')' : 'none'">
        </div>
        <span class="user-name">{{getDisplayedName()}}</span>
      </div>
    </a>
  </li>
</ul>