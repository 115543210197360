import { Injectable } from '@angular/core';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ApiResult } from '../ApiResult';
import { environment } from '../../../environments/environment';
import { Stock } from '../Stock';
import { ProductsService } from './products.service';

@Injectable({ providedIn: 'root' })
export class StocksService {
  private stocksUrl = environment.apiUrl + '/stocks';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    public router: Router,
    public productsService: ProductsService
  ) {}

  getStocks(): Observable<Stock[]> {
    console.log('products service getProducts');
    return this.http.get<Stock[]>(this.stocksUrl).pipe(
      map((stocks) =>
        stocks.map((stock) => ({
          ...stock, // This copies all the existing properties of the product
          updateDate: new Date(stock.updateDate), // Converts the creationDate string to a Date object
        }))
      )
    );
  }
}
