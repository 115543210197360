import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigurationValuesService } from '../dao/services/ConfigurationValues.service';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gondola-head',
  templateUrl: './gondola-head.component.html',
  styleUrls: ['./gondola-head.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
})
export class GondolaHeadComponent implements OnInit {
  reactiveForm!: FormGroup;
  gondolaHeadUrl: string;
  selectedFile: File | null = null;
  successText: string;
  errorText: string;
  isLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private configurationValuesService: ConfigurationValuesService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.loadInitialValues();
  }

  createForm() {
    this.reactiveForm = this.fb.group({
      sectionName: ['', [Validators.required, Validators.maxLength(40)]],
      image: [''],
    });
  }

  loadInitialValues() {
    this.configurationValuesService
      .getConfigurationValue('GONDOLA_HEAD_SECTION_NAME')
      .subscribe(
        (value) => this.reactiveForm.patchValue({ sectionName: value.value }),
        (error) => this.setErrorText('Erreur de chargement')
      );

    this.configurationValuesService
      .getConfigurationValue('GONDOLA_HEAD_IMAGE_URL')
      .subscribe(
        (value) =>
          (this.gondolaHeadUrl = environment.gondolaHeadFilesUrl + value.value),
        (error) => this.setErrorText('Erreur de chargement')
      );
  }

  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement && inputElement.files && inputElement.files.length > 0) {
      this.selectedFile = inputElement.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.gondolaHeadUrl = e.target?.result as string;
      };
      reader.readAsDataURL(this.selectedFile);
    }
  }

  validate() {
    if (this.reactiveForm.invalid) {
      this.setErrorText('Veuillez corriger les champs invalides.');
      return;
    }

    const sectionName = this.reactiveForm.get('sectionName')?.value;
    const formData = new FormData();
    formData.append('name', 'GONDOLA_HEAD_SECTION_NAME');
    formData.append('value', sectionName);

    this.isLoading = true;
    const observer = {
      next: () => {
        if (this.selectedFile) {
          this.uploadImage().then(
            () => this.setSuccessText('Sauvegarde effectuée'),
            (error) =>
              this.setErrorText("Erreur pendant le téléchargement de l'image")
          );
        } else {
          this.setSuccessText('Sauvegarde effectuée');
        }
      },
      error: (error) => this.setErrorText('Erreur pendant la sauvegarde'),
    };

    this.configurationValuesService.createOrUpdateConfigurationValue(
      formData,
      observer
    );
  }

  uploadImage(): Promise<void> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', this.selectedFile); // Ensure the field name is 'file'

      const observer = {
        next: () => resolve(),
        error: (error) => reject(error),
      };

      this.configurationValuesService.uploadGondolaHeadImage(
        formData,
        observer
      );
    });
  }

  removePhoto() {
    this.gondolaHeadUrl =
      environment.gondolaHeadFilesUrl + 'default-gondola-head.png'; // Set to default URL
    const formData = new FormData();
    formData.append('name', 'GONDOLA_HEAD_IMAGE_URL');
    formData.append('value', 'default-gondola-head.png');

    this.isLoading = true;
    const observer = {
      next: () => {
        this.setSuccessText("Fond remis à l'image par défaut");
        this.isLoading = false;
      },
      error: (error) => {
        this.setErrorText('Erreur lors de la suppression du fond');
        this.isLoading = false;
      },
    };

    this.configurationValuesService.createOrUpdateConfigurationValue(
      formData,
      observer
    );
  }

  setSuccessText(message: string) {
    this.successText = message;
    setTimeout(() => (this.successText = ''), 5000);
  }

  setErrorText(message: string) {
    this.errorText = message;
  }

  deleteMessages() {
    this.successText = '';
    this.errorText = '';
  }

  // Convenience getter for easy access to form fields
  get sectionName() {
    return this.reactiveForm.get('sectionName')!;
  }
}
