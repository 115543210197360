<div class="container-fluid" style="--bs-gutter-x: 0px;">

    <div class="flex-container">
        <div class="flex-row header">
            <div class="flex-col header-row l13p">N° LOT</div>
            <div class="flex-col header-row l16p">QTÉ</div>
            <div class="flex-col header-row l13p">COND.</div>
            <div class="flex-col header-row l13p">ORIG.</div>
            <div class="flex-col header-row l19p category-name">{{helpersService.getCategoryLabel(category)}}</div>
            <div class="flex-col header-row l13p">POIDS</div>
            <div class="flex-col header-row l13p">PRIX</div>
        </div>

        <div *ngFor="let group of orderLineFormGroups | keyvalue: keyAscComparator; let isLast=last; let i=index"
            [class.focused-row]="focusedRow === i "
            [class.angus-row]="group.value.controls['product'].value.angus && group.value.controls['product'].value.category == 'B'">
            <ng-container *ngIf="!group.value.get('isRemoved')?.value">
                <form [formGroup]="group.value" class="form-flex">
                    <div class="flex-row" [class.last-row]="isLast && (! newOrder  && false)">
                        <div class="flex-col l13p">
                            <input type="text" class="form-control" formControlName="lotNumber" [readonly]="isClosed()"
                                [class.white-background]="isActive(group.value) || isEditable(group.value)"
                                [class.no-border]="! (isActive(group.value) || isEditable(group.value))"
                                (input)="onInputChange($event)" (focus)="handleFocus($event, i)"
                                [class.isHidden]="!isEditable(group.value)"
                                (blur)="onFieldBlur(group.value, 'lotNumber');clearFocusedRow()"
                                [class.disabled-select]="isClosed()"
                                [class.is-invalid]="group.value.controls['lotNumber'].invalid && group.value.controls['lotNumber'].touched">
                        </div>
                        <div class="flex-col l16p">
                            <input type="text" class="form-control" formControlName="quantity"
                                [ngModel]="getDisplayQuantity(group.value.controls['quantity'].value)"
                                [readonly]="isClosed()" style="width: 50%;min-width:40px;margin-right: 5px;"
                                [class.white-background]="isActive(group.value) || isEditable(group.value)"
                                [class.no-border]="! (isActive(group.value) || isEditable(group.value))"
                                (focus)="handleFocus($event, i)" (blur)="clearFocusedRow()"
                                [class.disabled-select]="isClosed()" (input)="restrictInput($event, i)"
                                [class.isHidden]="!isEditable(group.value)"
                                [class.is-invalid]="group.value.controls['quantity'].invalid && group.value.controls['quantity'].touched">

                            <select class="form-control" formControlName="quantityType" [disabled]="isClosed()"
                                [class.white-background]="isActive(group.value) || isEditable(group.value)"
                                [class.no-border]="! (isActive(group.value) || isEditable(group.value))"
                                (input)="onInputChange($event)" (focus)="handleFocus($event, i)"
                                [class.disabled-select]="isClosed()"
                                (blur)="onFieldBlur(group.value, 'quantity');clearFocusedRow()"
                                [class.isHidden]="!isEditable(group.value)"
                                [class.isInvisible]="! isActive(group.value) && ! isFocusedRow(i)"
                                [class.is-invalid]="group.value.controls['quantityType'].invalid && group.value.controls['quantityType'].touched">
                                <option
                                    [hidden]="! isOptionAvailable(group.value.controls['product'].value.quantityType, 'P')"
                                    value="P">{{helpersService.getQuantityTypeLabel('P')}}</option>
                                <option
                                    [hidden]="! isOptionAvailable(group.value.controls['product'].value.quantityType, 'C')"
                                    value="C">{{helpersService.getQuantityTypeLabel('C')}}</option>
                                <option
                                    [hidden]="!isOptionAvailable(group.value.controls['product'].value.quantityType, 'K')"
                                    value="K">{{helpersService.getQuantityTypeLabel('K')}}</option>
                            </select>
                            <div *ngIf="group.value.controls['quantity'].invalid && group.value.controls['quantity'].touched"
                                class="invalid-feedback">

                            </div>
                        </div>
                        <div class="flex-col l13p">
                            <select class="form-control" formControlName="packaging"
                                [class.white-background]="isActive(group.value) || isEditable(group.value)"
                                [class.no-border]="! (isActive(group.value) || isEditable(group.value))"
                                (input)="onInputChange($event)" (focus)="handleFocus($event, i)"
                                [class.isHidden]="!isEditable(group.value)"
                                (blur)="onFieldBlur(group.value, 'packaging');clearFocusedRow()"
                                [class.isInvisible]="! isActive(group.value) && ! isFocusedRow(i)"
                                [class.disabled-select]="isClosed()"
                                [class.is-invalid]="group.value.controls['packaging'].invalid && group.value.controls['packaging'].touched">
                                <option
                                    [hidden]="! isOptionAvailable(group.value.controls['product'].value.packaging, 'F')"
                                    value="F">
                                    {{helpersService.getPackagingLabel('F')}}</option>
                                <option
                                    [hidden]="! isOptionAvailable(group.value.controls['product'].value.packaging, 'S')"
                                    value="S">
                                    {{helpersService.getPackagingLabel('S')}}</option>

                            </select>
                        </div>

                        <div class="flex-col l13p">
                            <select class="form-control" formControlName="origin"
                                [class.white-background]="isActive(group.value) || isEditable(group.value)"
                                [class.no-border]="! (isActive(group.value) || isEditable(group.value))"
                                (input)="onInputChange($event)" (focus)="handleFocus($event, i)"
                                [class.isHidden]="!isEditable(group.value)"
                                (blur)="onFieldBlur(group.value, 'origin');clearFocusedRow()"
                                [class.disabled-select]="isClosed()"
                                [class.isInvisible]="! isActive(group.value) && ! isFocusedRow(i)"
                                [class.is-invalid]="group.value.controls['origin'].invalid && group.value.controls['origin'].touched">
                                <option
                                    [hidden]="! isOptionAvailable(group.value.controls['product'].value.origin, 'F')"
                                    value="F">
                                    {{helpersService.getOriginLabel('F')}}</option>
                                <option
                                    [hidden]="! isOptionAvailable(group.value.controls['product'].value.origin, 'E')"
                                    value="E">
                                    {{helpersService.getOriginLabel('E')}}</option>
                                <option
                                    [hidden]="! isOptionAvailable(group.value.controls['product'].value.origin, 'B')"
                                    value="B">
                                    {{helpersService.getOriginLabel('B')}}</option>

                            </select>
                        </div>


                        <div class="flex-col product-name l19p " [class.selected_product]="isActive(group.value)">

                            <button type="button" aria-label="Close" class="btn-close empty-icon no-border"
                                [class.isVisible]="isEditable(group.value)  && (currentOrder?.status != 'T') && isDeletable(group.value)"
                                (click)="emptyLine(group.value)"></button>
                            {{ group.value.controls['product'].value.shortName ?
                            group.value.controls['product'].value.shortName :
                            group.value.controls['product'].value.name }}
                            <img src="assets/images/duplicate.svg" class="duplicate-icon"
                                (click)="duplicateProduct(group.value)"
                                [class.isVisible]="isEditable(group.value) && (currentOrder?.status != 'T') && isDuplicable(group.value)">
                        </div>

                        <div class="flex-col l13p">
                            <input type="number" class="form-control" formControlName="weight"
                                [class.white-background]="isActive(group.value) || isEditable(group.value)"
                                [class.no-border]="! (isActive(group.value) || isEditable(group.value))"
                                [class.isHidden]="!isEditable(group.value)" (input)="onInputChange($event)"
                                (focus)="handleFocus($event, i)"
                                (blur)="onFieldBlur(group.value, 'weight');clearFocusedRow()"
                                [class.disabled-select]="isClosed()"
                                [class.is-invalid]="group.value.controls['weight'].invalid && group.value.controls['weight'].touched">
                        </div>
                        <div class="flex-col l13p">
                            <input type="number" class="form-control" formControlName="price"
                                [class.white-background]="isActive(group.value) || isEditable(group.value)"
                                [class.no-border]="! (isActive(group.value) || isEditable(group.value))"
                                [class.isHidden]="!isEditable(group.value)" (input)="onInputChange($event)"
                                (focus)="handleFocus($event, i)"
                                (blur)="onFieldBlur(group.value, 'price');clearFocusedRow()"
                                [class.disabled-select]="isClosed()"
                                [class.is-invalid]="group.value.controls['price'].invalid && group.value.controls['price'].touched">
                        </div>
                    </div>
                </form>
            </ng-container>
        </div>
        <!-- Add configurable number of empty rows at the bottom of the table -->
        <div *ngFor="let i of [].constructor(numberOfEmptyRows)" class="empty-row">
            <div class="flex-row">
                <div class="flex-col l13p">&nbsp;</div>
                <div class="flex-col l16p">&nbsp;</div>
                <div class="flex-col l13p">&nbsp;</div>
                <div class="flex-col l13p">&nbsp;</div>
                <div class="flex-col l19p">&nbsp;</div>
                <div class="flex-col l13p">&nbsp;</div>
                <div class="flex-col l13p">&nbsp;</div>
            </div>
        </div>
        <div *ngIf="newOrder || editMode">
            <div class="flex-row last-row">
                <div class="flex-col l13p">
                </div>

                <div class="flex-col l16p">
                </div>
                <div class="flex-col l13p">
                </div>
                <div class="flex-col l13p">
                </div>
                <div class="flex-col product-name l19p">
                    <div class="input-group">
                        <input type="text" class="form-control input-search" (keyup)="onSearch($event)" #searchInput
                            (focus)="showResults = true" (blur)="onInputBlur()"
                            [class.no-bottom-border]="showResults && searchResults.length > 0" [(ngModel)]="searchText">
                    </div>
                    <ul *ngIf="showResults && searchResults.length > 0" class="search-results-dropdown">
                        <li *ngFor="let result of searchResults; let i = index" (click)="onSelectResult(result)"
                            [class.alternate-background]="i % 2 === 0"
                            [class.bottom-border-radius-10]="i == searchResults.length -1">
                            {{ result.name }}
                        </li>
                    </ul>
                </div>
                <div class="flex-col l13p">
                </div>
                <div class="flex-col l13p">

                </div>
            </div>
        </div>
    </div>
</div>