<div class="preloader" *ngIf="isLoading">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>


<h1>
    <span *ngIf="orderId == '0'; else existingOrder">
        Nouvelle Commande
    </span>
    <ng-template #existingOrder>
        Commande {{ orderId }}
    </ng-template>
</h1>



<div class="button-container">
    <a href="orders" class="returnToPrevious">
        <i-feather name="arrow-left"></i-feather> Retour aux listing des commandes
    </a>

    <div>
        <button *ngIf="orderId == '0'" class="btn btn-black" (click)="validateAllForms(false)">Enregistrer</button>
        <!--  <select #selectValue class="dropdown">
            <option value="1-col-avec-vide">1 col avec vide</option>
            <option value="2-cols-avec-vide">2 cols avec vide</option>
            <option value="1-col-sans-vide">1 col sans vide</option>
            <option value="2-cols-sans-vide">2 cols sans vide</option>
        </select> //selectValue.value
    -->
        <button class="btn btn-white" *ngIf="currentOrder != null && currentOrder.status != 'T'"
            (click)="activateEditMode()">Ajouter des produits</button>
        <button class="btn" [class.btn-white]="orderId == '0'" [class.btn-black]="orderId != '0'"
            *ngIf="currentOrder != null && currentOrder.status != 'T'"
            (click)="validateAllForms(true, '2-cols-avec-vide')">Valider la commande</button>
        <span style="width:100%" class="badge bg-success"
            *ngIf="currentOrder != null && currentOrder.status == 'T'">Validée</span>
    </div>
</div>

<div *ngIf="errorText || validationError" [@fadeInOut] class="alert show customize-alert fs-4 alert-danger fade">
    <p class="title">Erreur lors de l'enregistrement :</p>
    <span class="flex-grow-1" *ngIf="validationError">Veuillez vérifier les champs ci-dessous</span>
    <span class="flex-grow-1">{{errorText}}</span>
    <button type="button" aria-label="Close" class="btn-close btn-absolute-right" (click)="deleteMessages()"></button>
</div>
<div *ngIf="successText" [@fadeInOut] class="alert show customize-alert fs-4 alert-success fade">
    {{successText}}
    <button type="button" aria-label="Close" class="btn-close btn-absolute-right" (click)="deleteMessages()"></button>
</div>


<app-client-panel [society]="society" [clientComment]="clientComment"></app-client-panel>

<div class="container-fluid">
    <div class="row">
        <div class="{{categoryClass}}"
            *ngIf="products && products.length > 0 && (orderId == '0' || currentOrder != null)">
            <app-order-category-block (formGroupEmitter)="onFormGroupReady($event, 'B')" category="B"
                [allProducts]="products" [newOrder]="orderId == '0'" [currentOrder]="currentOrder"
                [orderDetails]="true"></app-order-category-block>
        </div>


        <div class="{{categoryClass}}"
            *ngIf="products && products.length > 0 && (orderId == '0' || currentOrder != null)">
            <app-order-category-block (formGroupEmitter)="onFormGroupReady($event, 'V')" category="V"
                [allProducts]="products" [newOrder]="orderId == '0'" [currentOrder]="currentOrder" [orderDetails]="true"
                [editMode]="editMode"></app-order-category-block>
        </div>
        <div class="{{categoryClass}}"
            *ngIf="products && products.length > 0 && (orderId == '0' || currentOrder != null)">
            <app-order-category-block (formGroupEmitter)="onFormGroupReady($event, 'P')" category="P"
                [allProducts]="products" [newOrder]="orderId == '0'" [currentOrder]="currentOrder" [orderDetails]="true"
                [editMode]="editMode"></app-order-category-block>
        </div>
        <div class=" {{categoryClass}}"
            *ngIf="products && products.length > 0 && (orderId == '0' || currentOrder != null)">
            <app-order-category-block (formGroupEmitter)="onFormGroupReady($event, 'A')" category="A"
                [allProducts]="products" [newOrder]="orderId == '0'" [currentOrder]="currentOrder" [orderDetails]="true"
                [editMode]="editMode"></app-order-category-block>
        </div>

        <div class=" {{categoryClass}}"
            *ngIf="products && products.length > 0 && (orderId == '0' || currentOrder != null)">
            <app-order-category-block (formGroupEmitter)="onFormGroupReady($event, 'O')" category="O"
                [allProducts]="products" [newOrder]="orderId == '0'" [currentOrder]="currentOrder" [orderDetails]="true"
                [editMode]="editMode"></app-order-category-block>
        </div>


        <div class=" {{categoryClass}}">
            <div class="comment-block">
                <label for="commentArea">Commentaires :</label>
                <form [formGroup]="parentFormGroup">
                    <textarea id="commentArea" class="form-control white-bg" rows="5" placeholder="Commentaire SOA"
                        formControlName="comments"> </textarea>
                </form>
            </div>
        </div>

    </div>
</div>