// choose-product-filter-panel.component.ts

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ProductFilter } from '../../dao/Filters';
import { HelpersService } from 'src/app/dao/services/helpers.service';

@Component({
  selector: 'app-choose-product-filter-panel',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './choose-product-filter-panel.component.html',
  styleUrls: ['./choose-product-filter-panel.component.scss'],
})
export class ChooseProductFilterPanelComponent implements OnInit {
  @Output() closeFilterPanel = new EventEmitter<void>();
  @Output() filterValueChanged = new EventEmitter<ProductFilter>();

  @Input() showFilterPanel = false;
  @Input() initialFilterCategory: string;

  filterName: string = '';
  filterCategory: string = '';
  filterStatus: string = '';
  filterCreationDate: string = '';
  isFadingOut: boolean;
  helpersService: HelpersService;
  filterNameControl = new FormControl('');

  constructor(helpersService: HelpersService) {
    this.helpersService = helpersService;
  }

  ngOnInit() {
    this.filterCategory = this.initialFilterCategory;
    this.initializeValueChanges();
  }

  initializeValueChanges() {
    this.filterNameControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.filterName = value;
        this.emitFilterChange();
      });
  }

  emitFilterChange() {
    let nbFilters = 0;
    if (this.filterName != undefined && this.filterName != '') {
      nbFilters++;
    }
    if (this.filterStatus != undefined && this.filterStatus != '') {
      nbFilters++;
    }
    if (this.filterCreationDate != undefined && this.filterCreationDate != '') {
      nbFilters++;
    }
    this.filterValueChanged.emit({
      nbFilters: nbFilters,
      name: this.filterName,
      status: this.filterStatus,
      creationDate: new Date(this.filterCreationDate),
    });
  }

  cancel() {
    this.isFadingOut = true;
    setTimeout(() => {
      this.showFilterPanel = false;
      this.isFadingOut = false;
    }, 500);
    this.closeFilterPanel.emit();
  }

  removeFilters() {
    this.filterNameControl.reset('');
    this.filterStatus = '';
    this.filterCreationDate = '';
    this.emitFilterChange();
  }
}
