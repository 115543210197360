import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { FormArray } from '@angular/forms';
import { User } from '../../dao/User';
import { Society } from '../../dao/Society';
import { UserService } from '../../dao/services/users.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { environment } from 'src/environments/environment';
import { AvatarService } from 'src/app/dao/services/avatar.service';

@Component({
  selector: 'app-clients-details',
  templateUrl: './clients-details.component.html',
  styleUrls: ['./clients-details.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: 0,
        })
      ),
      transition('void <=> *', animate(500)),
    ]),
  ],
})
export class ClientsDetailsComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private clipboard: Clipboard,
    private route: ActivatedRoute,
    private router: Router,
    private avatarService: AvatarService,
    private cdr: ChangeDetectorRef
  ) {
    this.userFromDb = {} as User;
    this.user = {} as User;
    this.user.societies = [];
  }

  successText: string;
  errorText: String;
  isLoading: boolean;
  reactiveForm!: FormGroup;
  user: User;
  userFromDb: User;
  societies: Society[];
  selectedAvatar: File | null = null;
  defaultAvatar: boolean = false;
  avatarUrl: string = this.getAvatarUrl();
  linkCopiedMessage: string = '';

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      // Access URL parameters using paramMap
      const id = params.get('id');
      console.log('ngOnInit' + id);

      if (id != undefined) {
        this.userService.getUser(id).subscribe((user: User) => {
          this.user = user;
          this.userFromDb = user;

          this.createReactiveForm();
          this.applyPasswordValidation();
          // Subscribe to changes in the isAdmin checkbox
          this.reactiveForm
            .get('isAdmin')
            ?.valueChanges.subscribe((isAdmin) => {
              this.toggleAdminValidators(isAdmin);
              this.reactiveForm.updateValueAndValidity();
            });
          this.updateAvatar();
        });
      } else {
        this.createReactiveForm();
        this.applyPasswordValidation();
        // Subscribe to changes in the isAdmin checkbox
        this.reactiveForm.get('isAdmin')?.valueChanges.subscribe((isAdmin) => {
          this.toggleAdminValidators(isAdmin);
          this.reactiveForm.updateValueAndValidity();
        });
      }
    });
  }

  getAvatarUrl() {
    this.defaultAvatar = !(
      this.user &&
      this.user.avatar != null &&
      this.user.avatar != undefined &&
      this.user.avatar != 'null'
    );
    return this.defaultAvatar
      ? environment.avatarImagesUrl + 'default_avatar.png'
      : environment.avatarImagesUrl + this.user.avatar;
  }

  updateAvatar() {
    const cacheBuster = new Date().getTime();
    this.avatarUrl = this.getAvatarUrl() + '?v=' + cacheBuster;
    console.log('client updateAvatar' + this.avatarUrl);
    console.log('detectChanges in updateAvatar before');
    this.cdr.detectChanges();
    console.log('detectChanges in updateAvatar after');
  }

  removeSelectedAvatar() {
    this.user.avatar = null;
    this.selectedAvatar = null;
    this.avatarUrl = this.getAvatarUrl(); // Reset to default or user's current avatar
    const selectedAvatarElement = document.getElementById(
      'selectedAvatar'
    ) as HTMLImageElement;
    if (selectedAvatarElement) {
      selectedAvatarElement.src = this.avatarUrl;
    }
    console.log('detectChanges in removeSelectedAvatar before');
    this.cdr.detectChanges();
    console.log('detectChanges in removeSelectedAvatar after');
  }

  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement && inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      this.selectedAvatar = file;

      const userId = this.user.id;
      console.log('onFileSelected 1');
      const objectUrl = URL.createObjectURL(file);
      this.avatarUrl = objectUrl;
      const selectedAvatarElement = document.getElementById(
        'selectedAvatar'
      ) as HTMLImageElement;
      if (selectedAvatarElement) {
        selectedAvatarElement.src = objectUrl;
        const connectedUser: User = this.userService.getConnectedUser();
        if (userId == connectedUser.id) {
          this.avatarService.setAvatarContent(objectUrl);
        }
      }
      this.cdr.detectChanges();
    }
  }

  createReactiveForm() {
    this.reactiveForm = this.fb.group(
      {
        capabilities: this.generateUserFormGroup(this.user),
        status: new FormControl(this.user.status ?? 1),
        groupName: new FormControl(this.user.groupName, [
          Validators.maxLength(255),
        ]),
        phone: new FormControl(this.user.phone, [Validators.maxLength(32)]),
        email: new FormControl(this.user.email, [
          Validators.required,
          Validators.email,
        ]),
        id: new FormControl(this.user.id),
        isAdmin: new FormControl(this.user.isAdmin),
        firstName: new FormControl(this.user.firstName),
        lastName: new FormControl(this.user.lastName),
        password: new FormControl(''),
        societies: this.fb.array(this.createSocieties(this.user)),
      },
      { validators: this.groupNameMandatoryIfMultipleSocieties() }
    );

    if (!this.user.id) {
      this.addSociety();
    }

    this.toggleAdminValidators(this.reactiveForm.get('isAdmin')?.value);
    this.applyPasswordValidation();
  }

  generateRandomString(id: number, length: number): string {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result = '';

    let seed = id + length;

    function pseudoRandom() {
      seed = (seed * 9301 + 49297) % 233280;
      return seed / 233280.0;
    }

    for (let i = 0; length > i; i++) {
      const randomIndex = Math.floor(pseudoRandom() * charactersLength);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

  getClientUrl() {
    if (
      this.user != undefined &&
      this.user != null &&
      this.user.id != undefined &&
      this.user.id != null
    ) {
      const randomString1 = this.generateRandomString(this.user.id, 6);
      const randomString2 = this.generateRandomString(this.user.id, 9);
      const token: string = `${randomString1}${this.user.id}${randomString2}`;

      return `${environment.clientLink}${token}`;
    }
    return '';
  }

  deleteClientWithConfirmation() {
    if (window.confirm('Etes vous sur de vouloir supprimer ce client ?')) {
      this.deleteClient();
    } else {
      console.log('Delete canceled');
    }
  }

  deleteClient() {
    if (
      this.user.id == null ||
      this.user.id == undefined ||
      this.user.id == 0
    ) {
      this.router.navigate(['/clients']);
    } else {
      const observer = {
        next: () => {
          this.router.navigate(['/clients']);
        },
        error: (error: HttpErrorResponse) => {
          // Handle errors here
          if (error.error instanceof ErrorEvent) {
            // Client-side error
            this.setErrorText(error.error.message);
          } else {
            // Server-side error
            console.log(error);
            if (error.error) {
              this.setErrorText(error.error);
            } else {
              this.setErrorText(error.message);
            }
          }
        },
      };
      this.userService.deleteUser(this.userFromDb, observer);
    }
  }

  copyLinkToClipboard() {
    const pending = this.clipboard.beginCopy(this.getClientUrl());

    let remainingAttempts = 3;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        // Remember to destroy when you're done!
        pending.destroy();

        // Set feedback message
        if (result) {
          this.linkCopiedMessage = 'Lien copié !';
        } else {
          this.linkCopiedMessage = 'Erreur lors de la copie du lien.';
        }

        // Remove the message after a few seconds
        setTimeout(() => {
          this.linkCopiedMessage = '';
        }, 3000);
      }
    };
    attempt();
  }

  // Convenience getter for easy access to form array control
  get formSocieties() {
    return this.reactiveForm.get('societies') as FormArray;
  }

  // Method to remove a control from the FormArray
  removeSociety(index: number) {
    this.formSocieties.removeAt(index); // Remove the control at the specified index
  }

  // Method to add a new control to the FormArray
  addSociety() {
    const newSociety = this.fb.group({
      // Define the structure of each item in the FormArray
      code2b: ['', Validators.required],
      callName: ['', Validators.required],
      societyName: ['', Validators.required],
      address: ['', Validators.required],
      id: ['0', Validators.required],
    });

    this.formSocieties.push(newSociety); // Push the new item FormGroup into the FormArray
  }

  createSocieties(user: User) {
    var societies: FormGroup[] = [];

    for (let i = 0; i < user.societies.length; i++) {
      societies.push(this.createSociety(user.societies[i]));
    }
    return societies;
  }

  // Method to create an item FormGroup with initial values
  createSociety(society: Society) {
    return this.fb.group({
      code2b: [society.code2b],
      callName: [society.callName],
      societyName: [society.societyName],
      id: [society.id],
      address: [society.address],
    });
  }

  get status() {
    return this.reactiveForm.get('status')!;
  }

  get groupName() {
    return this.reactiveForm.get('groupName')!;
  }

  get phone() {
    return this.reactiveForm.get('phone')!;
  }

  get email() {
    return this.reactiveForm.get('email')!;
  }

  get id() {
    return this.reactiveForm.get('id')!;
  }

  get firstName() {
    return this.reactiveForm.get('firstName')!;
  }

  get lastName() {
    return this.reactiveForm.get('lastName')!;
  }

  get password() {
    return this.reactiveForm.get('password')!;
  }

  get avatar() {
    return this.reactiveForm.get('avatar')!;
  }

  markFormArrayControlsAsTouched(formArray: FormArray) {
    formArray.markAsTouched();
    formArray.controls.forEach((control) => {
      if (control instanceof FormGroup) {
        this.markFormGroupControlsAsTouched(control);
      }
    });
  }

  markFormGroupControlsAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupControlsAsTouched(control);
      } else if (control instanceof FormArray) {
        this.markFormArrayControlsAsTouched(control);
      }
    });
  }

  atLeastOneSociety(): ValidatorFn {
    return (formArray: AbstractControl): ValidationErrors | null => {
      const societies = formArray as FormArray;
      return societies.length < 1 ? { atLeastOneSociety: true } : null;
    };
  }

  groupNameMandatoryIfMultipleSocieties(): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const isAdminControl = (formGroup as FormGroup).get('isAdmin');
      const groupNameControl = (formGroup as FormGroup).get('groupName');
      const societiesControl = (formGroup as FormGroup).get(
        'societies'
      ) as FormArray;

      if (isAdminControl?.value) {
        // If isAdmin is checked, clear any existing errors and return null
        if (groupNameControl?.hasError('groupNameMandatory')) {
          groupNameControl.setErrors(null);
        }
        return null;
      }

      if (
        societiesControl &&
        societiesControl.length >= 2 &&
        !groupNameControl?.value
      ) {
        groupNameControl?.setErrors({ groupNameMandatory: true });
        return { groupNameMandatory: true };
      } else {
        // Ensure the previous error is cleared if condition is no longer met
        if (groupNameControl?.hasError('groupNameMandatory')) {
          groupNameControl.setErrors(null);
        }
      }
      return null;
    };
  }

  getFormValidationErrors() {
    let ret: string = '';

    const errors: any = {};
    Object.keys(this.reactiveForm.controls).forEach((key) => {
      const controlErrors = this.reactiveForm.get(key)?.errors;
      if (controlErrors) {
        errors[key] = controlErrors;
        ret += controlErrors;
      }
    });

    return ret.trim();
  }

  private generateUserFormGroup(user: User) {
    return this.fb.group({
      status: this.fb.control({ value: user.status, disabled: false }, []),
      id: this.fb.control({ value: user.id, disabled: false }, []),
      firstName: this.fb.control(
        { value: user.firstName, disabled: false },
        []
      ),
      lastName: this.fb.control({ value: user.lastName, disabled: false }, []),
      password: this.fb.control({ value: user.password, disabled: false }, []),
      groupName: this.fb.control(
        {
          value: user.groupName,
          disabled: false,
        },
        [Validators.maxLength(255)]
      ),
      phone: this.fb.control(
        {
          value: user.phone,
          disabled: false,
        },
        [Validators.maxLength(32)]
      ),
      email: this.fb.control(
        {
          value: user.email,
          disabled: false,
        },
        [Validators.email]
      ),
    });
  }

  getValidationErrors(index: number) {
    const item = this.formSocieties.at(index);
    if (!item) return null; // If item doesn't exist, return null

    const code2b = item.get('code2b');
    const callName = item.get('callName');
    const societyName = item.get('societyName');
    const address = item.get('address');
    const id = item.get('id');

    const errors = {
      code2b: code2b?.errors,
      callName: callName?.errors,
      societyName: societyName?.errors,
      address: address?.errors,
      id: id?.errors,
    };

    return errors;
  }

  public deleteMessages() {
    this.successText = '';
    this.errorText = '';
  }

  private setSuccessText(message: string) {
    this.successText = message;

    setTimeout(() => {
      this.successText = ''; // Retirer le message après 5 secondes
    }, 5000);
  }

  private setErrorText(message: string) {
    this.errorText = message;
  }

  applyPasswordValidation() {
    const passwordControl = this.reactiveForm.get('password');
    const isAdminControl = this.reactiveForm.get('isAdmin');

    // Initially apply or remove validators based on the isAdmin control state
    this.setOrClearValidators(isAdminControl.value, passwordControl);

    // Subscribe to changes in the isAdmin form control value
    isAdminControl.valueChanges.subscribe((isAdmin) => {
      this.setOrClearValidators(isAdmin, passwordControl);
    });
  }

  // Method to set or clear validators based on isAdmin flag
  private setOrClearValidators(
    isAdmin: boolean,
    passwordControl: AbstractControl
  ) {
    // Clear existing validators
    passwordControl.clearValidators();

    // If isAdmin is checked, apply validators
    if (isAdmin) {
      const validators = [Validators.minLength(8), Validators.maxLength(255)];

      // Apply required validator only if it's a new user or the password field is not empty
      if (!this.user.id || passwordControl.value) {
        validators.push(Validators.required);
      }

      passwordControl.setValidators(validators);
    }
    // Update validators and re-evaluate the form control's validity
    passwordControl.updateValueAndValidity();
  }

  toggleAdminValidators(isAdmin: boolean) {
    if (isAdmin) {
      this.addAdminValidators();
      this.removeSocietyValidators();
      this.reactiveForm.get('groupName')?.clearValidators();
    } else {
      this.removeAdminValidators();
      this.addSocietyValidators();
      this.reactiveForm
        .get('groupName')
        ?.setValidators([Validators.maxLength(255)]);
    }
    this.updateFormControlsValidity();
  }

  addAdminValidators() {
    this.reactiveForm.get('firstName')?.setValidators(Validators.required);
    this.reactiveForm.get('lastName')?.setValidators(Validators.required);
    this.reactiveForm
      .get('password')
      ?.setValidators([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(255),
      ]);
  }

  removeAdminValidators() {
    this.reactiveForm.get('firstName')?.clearValidators();
    this.reactiveForm.get('lastName')?.clearValidators();
    this.reactiveForm.get('password')?.clearValidators();
  }

  addSocietyValidators() {
    this.reactiveForm.get('societies')?.setValidators(this.atLeastOneSociety());
    this.reactiveForm.get('societies')?.updateValueAndValidity();
  }

  removeSocietyValidators() {
    this.reactiveForm.get('societies')?.clearValidators();
    this.reactiveForm.get('societies')?.updateValueAndValidity();
  }

  updateFormControlsValidity() {
    // Update the validity of all controls in the form
    Object.keys(this.reactiveForm.controls).forEach((key) => {
      const control = this.reactiveForm.get(key);
      control?.updateValueAndValidity();
    });
  }

  checkForErrors(formGroup: FormGroup | FormArray): void {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);

      // Check for control errors
      if (control.errors) {
        console.log(`Errors in ${key}:`, control.errors);
      }

      // Recursively check for nested FormGroup or FormArray
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.checkForErrors(control);
      }
    });
  }

  getName(fieldName): string {
    var ret = fieldName;
    if (fieldName == 'phone') {
      ret = 'Téléphone';
    }
    if (fieldName == 'email') {
      ret = 'E-mail';
    }
    if (fieldName == 'code2b') {
      ret = 'Code 2B';
    }
    if (fieldName == 'callName') {
      ret = "Nom d'appel";
    }
    if (fieldName == 'societyName') {
      ret = 'Nom de société';
    }
    if (fieldName == 'address') {
      ret = 'Adresse';
    }
    if (fieldName == 'groupName') {
      ret = 'Groupe';
    }
    if (fieldName == 'password') {
      ret = 'Mot de passe';
    }

    if (fieldName == 'lastName') {
      ret = 'Nom';
    }

    if (fieldName == 'firstName') {
      ret = 'Prénom';
    }

    return ret;
  }

  public validate(): void {
    const avatar: File | null = this.selectedAvatar;
    this.setErrorText('');

    if (this.reactiveForm.get('isAdmin')?.value == true) {
      while (this.formSocieties.length !== 0) {
        this.formSocieties.removeAt(0);
      }
    }

    if (this.reactiveForm.invalid) {
      this.checkForErrors(this.reactiveForm);
      console.log('invalid');

      const invalidFields: string[] = [];

      // Check for errors in the main form controls
      Object.keys(this.reactiveForm.controls).forEach((key) => {
        const control = this.reactiveForm.get(key);
        if (control && control.invalid) {
          if (key !== 'societies') {
            invalidFields.push(this.getName(key));
          }
          console.log(`Invalid field: ${key}`);

          const controlErrors = control.errors;
          if (controlErrors) {
            console.log('-> errors');
            Object.keys(controlErrors).forEach((keyError) => {
              console.log(
                `Key control: ${key}, keyError: ${keyError}, errValue:`,
                controlErrors[keyError]
              );
            });
          }
        }
      });

      if (this.reactiveForm.get('isAdmin')?.value != true) {
        // Check for errors in the societies FormArray
        this.formSocieties.controls.forEach(
          (formGroup: AbstractControl, index: number) => {
            const societyControls = formGroup as FormGroup;
            Object.keys(societyControls.controls).forEach((key) => {
              const control = societyControls.get(key);
              if (control && control.invalid) {
                invalidFields.push(
                  `Société n° ${index + 1} - ${this.getName(key)}`
                );
                console.log(
                  `Invalid society field: Society ${index + 1} - ${key}`
                );

                const controlErrors = control.errors;
                if (controlErrors) {
                  console.log('-> errors');
                  Object.keys(controlErrors).forEach((keyError) => {
                    console.log(
                      `Key control: Society ${
                        index + 1
                      } - ${key}, keyError: ${keyError}, errValue:`,
                      controlErrors[keyError]
                    );
                  });
                }
              }
            });
          }
        );
      }
      Object.keys(this.reactiveForm.controls).forEach((field) => {
        const control = this.reactiveForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });

      const errorMessage = `Veuillez corriger les champs suivants: ${invalidFields.join(
        ', '
      )}.`;
      this.setErrorText(errorMessage);
      return;
    }

    const formData = new FormData();

    formData.append('id', this.reactiveForm.get('id')?.value ?? 0);
    formData.append('status', this.reactiveForm.get('status')?.value ?? 1);
    formData.append(
      'groupName',
      this.reactiveForm.get('groupName')?.value ?? ''
    );
    formData.append('phone', this.reactiveForm.get('phone')?.value);
    formData.append('email', this.reactiveForm.get('email')?.value);
    formData.append(
      'isAdmin',
      this.reactiveForm.get('isAdmin')?.value ?? false
    );
    formData.append(
      'firstName',
      this.reactiveForm.get('firstName')?.value ?? ''
    );
    formData.append('lastName', this.reactiveForm.get('lastName')?.value ?? '');
    formData.append('password', this.reactiveForm.get('password')?.value ?? '');

    formData.append(
      'societiesJson',
      JSON.stringify(this.reactiveForm.get('societies')?.value)
    );

    if (this.selectedAvatar) {
      formData.append(
        'avatarFile',
        this.selectedAvatar,
        this.selectedAvatar.name
      );
    } else if (this.user.avatar === null) {
      formData.append('removeAvatar', 'true');
    }

    this.isLoading = true;

    const apiMethod = this.user.id
      ? 'updateUserWithAvatar'
      : 'createUserWithAvatar';

    this.userService[apiMethod](formData).subscribe({
      next: (response) => {
        if (this.user && this.user.id) {
          this.setSuccessText('Utilisateur mis à jour');
        } else {
          this.setSuccessText('Utilisateur créé');
          this.user = response;
          if (this.user && this.user.id && !this.user.isAdmin) {
            // SOA-174 CLIENTS - Envoi automatique d'email à la création de l'utilisateur
            //this.sendLinkByEmail();
          }
        }
        this.user = response;
        this.isLoading = false;
      },
      error: (error) => {
        let errorMessage = "Erreur lors de la mise à jour de l'utilisateur";

        if (error.error instanceof ErrorEvent) {
          // Client-side error
          errorMessage += ` : ${error.error.message}`;
        } else {
          // Server-side error
          if (error.error && typeof error.error === 'string') {
            errorMessage += ` : ${error.error}`;
          } else if (error.error && typeof error.error === 'object') {
            errorMessage += ` : ${JSON.stringify(error.error)}`;
          } else {
            errorMessage += ` : ${error.message}`;
          }
        }
        this.setErrorText(errorMessage);
        console.error('Error:', error);
        this.isLoading = false;
      },
    });
  }

  sendLinkByEmail() {
    this.isLoading = true;
    this.userService.sendLinkByEmail(this.getClientUrl(), this.user).subscribe({
      next: (response) => {
        this.setSuccessText('Envoi email effectué');
        this.isLoading = false;
      },
      error: (error) => {
        this.setErrorText("Erreur lors de l'envoi de l'Email");
        console.error('Error:', error);
        this.isLoading = false;
      },
    });
  }

  sendLinkBySms() {
    this.isLoading = true;
    this.userService.sendLinkBySms(this.getClientUrl(), this.user).subscribe({
      next: (response) => {
        this.setSuccessText('Envoi SMS effectué');
        this.isLoading = false;
      },
      error: (error) => {
        this.setErrorText("Erreur lors de l'envoi du SMS");
        console.error('Error:', error);
        this.isLoading = false;
      },
    });
  }
}
