import { CommonModule } from '@angular/common';
import {
  Component,
  AfterViewInit,
  EventEmitter,
  Output,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  NgbModal,
  NgbAccordionModule,
  NgbCarouselModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AuthService } from 'src/app/auth.service';
import { User } from 'src/app/dao/User';
import { AvatarService } from 'src/app/dao/services/avatar.service';
import { UserService } from 'src/app/dao/services/users.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-vertical-navigation',
  standalone: true,
  imports: [
    NgbAccordionModule,
    NgScrollbarModule,
    NgbCarouselModule,
    NgbDropdownModule,
    CommonModule,
  ],
  templateUrl: './vertical-navigation.component.html',
  styleUrl: './vertical-navigation.component.scss',
})
export class VerticalNavigationComponent implements AfterViewInit, OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public showSearch = false;

  public defaultAvatar: boolean;
  avatarUrl: string;

  getDisplayedName() {
    if (this.userService.getConnectedUser()) {
      const user: User = this.userService.getConnectedUser();
      return user.firstName + ' ' + user.lastName;
    }
    return '';
  }

  changeAvatarUrl() {
    const user: User = this.userService.getConnectedUser();
    this.defaultAvatar = !(
      user != null &&
      user.avatar != null &&
      user.avatar != undefined &&
      user.avatar != 'null'
    );
    console.log('changeAvatarUrl in Vertical navigation : ' + user.avatar);
    this.avatarUrl = this.defaultAvatar
      ? environment.avatarImagesUrl + 'default_avatar.png'
      : environment.avatarImagesUrl + user.avatar;
  }

  // This is for Notifications

  public selectedLanguage: any = {
    language: 'Français',
    code: 'fr',
    icon: 'fr',
  };

  public languages: any[] = [
    {
      language: 'Français',
      code: 'fr',
      icon: 'fr',
    },
  ];

  constructor(
    private modalService: NgbModal,
    private translate: TranslateService,
    public router: Router,
    private userService: UserService,
    private avatarService: AvatarService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef
  ) {
    translate.setDefaultLang('fr');
  }
  ngOnInit(): void {
    this.avatarService.getAvatarContent().subscribe((content) => {
      console.log('FULL COMPONENT AVATAR  CHANGED ');
      const selectedAvatarElement = document.getElementById(
        'menuAvatar'
      ) as HTMLImageElement;
      if (
        selectedAvatarElement &&
        content != null &&
        content != undefined &&
        content != ''
      ) {
        console.log('getAvatarContent in FULL');
        selectedAvatarElement.src = content;
        console.log('detectChanges in navigation component before');
        this.avatarUrl = content;
        this.cdr.detectChanges();
        console.log('detectChanges in navigation component after');
      }
    });
    this.changeAvatarUrl();
  }

  changeLanguage(lang: any) {
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
  }

  logout() {
    this.authService.doLogout();
    this.router.navigate(['/login']);
  }

  ngAfterViewInit() {}
}
