<!-- products.component.html -->

<h1>Produits</h1>

<div style="margin-bottom: 1rem;display: flex;place-content: space-between;">
    <div style="display: flex; gap: 1rem; align-items: center;">
        <select class="category-select" id="filterCategory" [(ngModel)]="filterCategory"
            (ngModelChange)="categoryFilterChanges()">
            <option value="" disabled selected>Catégorie</option>
            <option value="B">{{helpersService.getCategoryLabel("B")}}</option>
            <option value="P">{{helpersService.getCategoryLabel("P")}}</option>
            <option value="V">{{helpersService.getCategoryLabel("V")}}</option>
            <option value="A">{{helpersService.getCategoryLabel("A")}}</option>
            <option value="O">{{helpersService.getCategoryLabel("O")}}</option>
        </select>
        <app-filter-button (pressed)="showFilterPanel=true" class="filter-button"
            [numberFilters]="productFilter ? productFilter.nbFilters : 0"></app-filter-button>
    </div>
    <div>
        <a href="/gondola-head/" class="btn btn-white mr-0">Coup de coeur</a>
        <a href="/products-details/" class="btn btn-black mr-0">Créer un produit</a>
    </div>
</div>

<app-choose-product-filter-panel [showFilterPanel]="showFilterPanel" [initialFilterCategory]="filterCategory"
    (closeFilterPanel)="closeFilterPanel()"
    (filterValueChanged)="filterChanges($event)"></app-choose-product-filter-panel>

<div class="card">
    <div class="card-body">
        <div class="table-responsive">
            <table class="table align-middle text-nowrap">
                <thead>
                    <tr>
                        <th>Date de création</th>
                        <th>Nom du produit</th>
                        <th>Catégorie</th>
                        <th>Statut</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                    <tr *ngFor="let product of displayedProducts" cdkDrag>
                        <td class="drag-handle-cell">
                            <div cdkDragHandle class="drag-handle">
                                <img src="/assets/images/up-down-arrow.png">
                            </div>{{ product.creationDate | date: 'dd.MM.yy | HH:mm'}}
                        </td>
                        <td>{{product.name}}</td>
                        <td>{{helpersService.getCategoryLabel(product.category)}}</td>
                        <td>
                            <span style="width:100%" class="badge bg-success" *ngIf="product.status == 1">Actif</span>
                            <span style="width:100%" class="badge bg-danger" *ngIf="product.status != 1">Inactif</span>
                        </td>
                        <td><a class="link" href="/products-details/{{product.id}}">Modifier</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>