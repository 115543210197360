import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, KeyValue } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Product } from 'src/app/dao/Product';
import { HelpersService } from 'src/app/dao/services/helpers.service';
import { SocietiesService } from 'src/app/dao/services/societies.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { OrderLine } from 'src/app/dao/OrderLine';
import { ChangeDetectorRef } from '@angular/core';
import { Order } from 'src/app/dao/Order';

@Component({
  selector: 'app-order-category-block-print',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './order-category-block-print.component.html',
  styleUrl: './order-category-block-print.component.scss',
})
export class OrderCategoryBlockPrintComponent {
  @Input() allProducts: Product[];
  @Input() category: string;
  @Input() emptyCols: boolean;
  @Input() currentOrder: Order;
  @Input() orderDetails: boolean;
  @Input() numberOfEmptyRows: number = 3;

  orderLineFormGroups: { [key: string]: FormGroup } = {};

  constructor(
    private fb: FormBuilder,
    public helpersService: HelpersService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.allProducts = this.allProducts.filter(
      (p) => p.category == this.category
    );

    this.createFormGroups();
  }

  keyAscComparator(a: KeyValue<string, any>, b: KeyValue<string, any>): number {
    return parseInt(a.key, 10) - parseInt(b.key, 10);
  }
  createFormGroups() {
    let index = 0;
    // Existing order
    let remainingOrderLines = [...this.currentOrder.orderLines];
    this.allProducts.forEach((product) => {
      if (product.isInBdc) {
        let foundIndex = remainingOrderLines.findIndex(
          (c) => c.product.id == product.id
        );
        if (foundIndex > -1) {
          let foundOrderLine = remainingOrderLines[foundIndex];
          this.orderLineFormGroups[index] = this.fb.group({
            id: foundOrderLine.id,
            formId: this.category + index++,
            lotNumber: foundOrderLine.lotNumber,
            quantity: foundOrderLine.quantity,
            quantityType: foundOrderLine.quantityType,
            origin: foundOrderLine.origin,
            packaging: foundOrderLine.packaging,
            weight: foundOrderLine.weight,
            price: foundOrderLine.price,
            product: product,
            virtual: true,
            isRemove: false,
          });
          remainingOrderLines.splice(foundIndex, 1); // Remove processed order line
        } else {
          if (this.emptyCols) {
            this.orderLineFormGroups[index] = this.fb.group({
              formId: this.category + index++,
              lotNumber: '',
              quantity: '',
              quantityType: '',
              origin: '',
              packaging: '',
              weight: '',
              price: '',
              product: product,
              virtual: true,
              isRemove: false,
            });
          }
        }
      }
    });

    // Add remaining order lines that were not matched with a product
    remainingOrderLines.forEach((orderLine) => {
      if (orderLine.product.category == this.category) {
        this.orderLineFormGroups[index] = this.fb.group({
          id: orderLine.id,
          formId: this.category + index++,
          lotNumber: orderLine.lotNumber,
          quantity: orderLine.quantity,
          quantityType: orderLine.quantityType,
          origin: orderLine.origin,
          packaging: orderLine.packaging,
          weight: orderLine.weight,
          price: orderLine.price,
          product: orderLine.product,
          virtual: true,
          isRemove: false,
        });
      }
    });
  }
}
