<h1>Clients</h1>

<div style="margin-bottom: 1rem; display: flex; justify-content: space-between;">
    <app-filter-button (pressed)="showFilterPanel=true"
        [numberFilters]="clientFilter ? clientFilter.nbFilters : 0"></app-filter-button>
    <a href="/clients-details/" class="btn btn-black mr-0">Créer un client</a>
</div>

<app-choose-client-filter-panel [showFilterPanel]="showFilterPanel" (closeFilterPanel)="closeFilterPanel()"
    (filterValueChanged)="filterChanges($event)"></app-choose-client-filter-panel>

<div class="card">
    <div class="card-body">
        <div class="table-responsive">
            <table class="table align-middle text-nowrap">
                <thead>
                    <tr>
                        <th [hidden]="true">
                            Id
                            <span class="sorting-icon" [ngClass]="sortingDirection('Id')"></span>
                        </th>
                        <th (click)="sort('Code2b')">
                            Code Client 2B
                            <span class="sorting-icon" [ngClass]="sortingDirection('Code2b')"></span>
                        </th>
                        <th (click)="sort('CallName')">
                            Nom d'appel
                            <span class="sorting-icon" [ngClass]="sortingDirection('CallName')"></span>
                        </th>
                        <th (click)="sort('GroupName')">
                            Groupe
                            <span class="sorting-icon" [ngClass]="sortingDirection('GroupName')"></span>
                        </th>
                        <th (click)="sort('SocietyName')">
                            Nom de la société
                            <span class="sorting-icon" [ngClass]="sortingDirection('SocietyName')"></span>
                        </th>
                        <th (click)="sort('Status')">
                            Statut
                            <span class="sorting-icon" [ngClass]="sortingDirection('Status')"></span>
                        </th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let userSociety of displayedUserSocieties">
                        <td [hidden]="true" [class.yellowBackground]="userSociety.isAdmin">{{userSociety.id}}</td>
                        <td [class.yellowBackground]="userSociety.isAdmin">{{userSociety.code2b}}</td>
                        <td [class.yellowBackground]="userSociety.isAdmin">{{userSociety.callName}}</td>
                        <td [class.yellowBackground]="userSociety.isAdmin">{{userSociety.groupName}}</td>
                        <td [class.yellowBackground]="userSociety.isAdmin">{{userSociety.societyName}}</td>
                        <td [class.yellowBackground]="userSociety.isAdmin">
                            <span style="width: 100%" class="badge bg-warning"
                                *ngIf="userSociety.status == 1 && userSociety.isAdmin">Admin</span>
                            <span style="width: 100%" class="badge bg-success"
                                *ngIf="userSociety.status == 1 && !userSociety.isAdmin">Actif</span>
                            <span style="width: 100%" class="badge bg-danger"
                                *ngIf="userSociety.status != 1">Inactif</span>
                        </td>
                        <td [class.yellowBackground]="userSociety.isAdmin">
                            <a class="link" href="/clients-details/{{userSociety.id}}">Modifier</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer">
        <ng-container *ngFor="let page of getPagesToShow()">
            <button class="btn" (click)="goToPage(page)" [class.font-weight-bold]="currentPage === page">
                {{ page }}
            </button>
        </ng-container>
    </div>
</div>