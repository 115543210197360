<h1>Documents</h1>

<div style="margin-bottom: 1rem;display: flex;place-content: space-between;">
    <app-filter-button (pressed)="showFilterPanel=true"
        [numberFilters]="documentFilter ? documentFilter.nbFilters : 0"></app-filter-button>
    <a href="/documents-details/" class="btn btn-black mr-0"> Créer un document </a>
</div>

<app-choose-document-filter-panel [showFilterPanel]="showFilterPanel" (closeFilterPanel)="closeFilterPanel()"
    (filterValueChanged)="filterChanges($event)"></app-choose-document-filter-panel>

<div class="card">
    <div class="card-body">
        <div class="table-responsive">
            <table class="table align-middle text-nowrap">
                <thead>
                    <tr>
                        <th>Date de création</th>
                        <th>Type de document</th>
                        <th>Nom du document</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let document of documents">
                        <td>{{ document.creationDate | date: 'dd.MM.yy'}}</td>
                        <td>{{ helpersService.getDocumentTypeLabel(document.type) }}</td>
                        <td>{{ document.name }}</td>
                        <td><a class="link" href="/documents-details/{{document.id}}">Modifier</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer">
        <ng-container *ngFor="let page of getPagesToShow()">
            <button class="btn" (click)="goToPage(page)" [class.font-weight-bold]="currentPage === page">
                {{ page }}
            </button>
        </ng-container>
    </div>
</div>