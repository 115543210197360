import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AvatarService {
  private avatarContentSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  setAvatarContent(content: string): void {
    this.avatarContentSubject.next(content);
  }

  getAvatarContent(): Observable<string> {
    return this.avatarContentSubject.asObservable();
  }
}
