<h1>Section "Coup de Coeur"</h1>

<div class="card">
    <div class="container-fluid py-3">
        <div style="margin-bottom: 1rem;display: flex;place-content: end;">
            <button type="button" class="btn btn-white" (click)="validate()"> Enregistrer
            </button>
        </div>

        <div *ngIf="errorText" [@fadeInOut] class="alert show customize-alert fs-4 alert-danger fade">
            {{errorText}}
            <button type="button" aria-label="Close" class="btn-close btn-absolute-right"
                (click)="deleteMessages()"></button>
        </div>
        <div *ngIf="successText" [@fadeInOut] class="alert show customize-alert fs-4 alert-success fade">
            {{successText}}
            <button type="button" aria-label="Close" class="btn-close btn-absolute-right"
                (click)="deleteMessages()"></button>
        </div>

        <div class="row justify-content-left my-5">
            <div class="col-7">
                <form *ngIf="reactiveForm" [formGroup]="reactiveForm" #form="ngForm">
                    <div class="row">
                        <div class="col mb-2">
                            <label for="sectionName" class="form-label">Nom de la section :</label>
                            <input type="text" id="sectionName" name="sectionName" formControlName="sectionName"
                                placeholder="Coup de Coeur" maxlength="40" class="form-control form-control-sm" required
                                [class.is-invalid]="sectionName.invalid && (sectionName.dirty || sectionName.touched)" />

                            <div *ngIf="sectionName.invalid && (sectionName.dirty || sectionName.touched)"
                                class="invalid-feedback">
                                <div *ngIf="sectionName.errors?.['required']">
                                    Le champ "Nom de la section" est obligatoire
                                </div>
                                <div *ngIf="sectionName.errors?.['maxlength']">
                                    La taille du champ "Nom de la section" ne doit pas excéder 40 caractères.
                                </div>
                            </div>
                        </div>
                    </div>

                    <input type="file" id="fileInput" (change)="onFileSelected($event)" accept="image/*"
                        style="display: none;">
                    <label class="form-label">Photo de fond de la section :</label>
                    <div class="navbar-brand">
                        <img id="selectedGondolaHead" class="selectedGondolaHead" [src]="gondolaHeadUrl" />
                        <div class="buttons">
                            <label for="fileInput" class="btn btn-black">
                                <i class="fa-solid fa-pencil"></i>
                                Modifier la photo
                            </label>

                            <button type="button" class="btn btn-white" (click)="removePhoto()">
                                <div class="button-content">
                                    <i class="fa-regular fa-circle-xmark"></i>
                                    Supprimer la photo
                                </div>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>