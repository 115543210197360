import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserService } from '../../dao/services/users.service';

import { FormsModule } from '@angular/forms';
import { Society } from 'src/app/dao/Society';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SocietiesService } from 'src/app/dao/services/societies.service';

@Component({
  selector: 'app-choose-client-panel',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './choose-client-panel.component.html',
  styleUrl: './choose-client-panel.component.scss',
})
export class ChooseClientPanelComponent {
  @Output() closePanel = new EventEmitter<void>(); // Emit an event without any value

  @Input() showOrderPanel = false;
  societies: any[] = [];
  searchTerm: string = '';
  selectedSociety: Society | null = null;
  isFadingOut: boolean;
  constructor(
    private societiesService: SocietiesService,
    private router: Router
  ) {}

  searchClients() {
    if (this.searchTerm.trim().length <= 0) {
      this.societies = [];
    } else {
      this.societiesService
        .getSocietiesWithQuery(this.searchTerm)
        .subscribe((societies) => {
          this.societies = societies;
        });
    }
  }

  selectSociety(society: Society) {
    this.selectedSociety = society; // Définit la société sélectionnée
  }

  createOrder() {
    this.isFadingOut = true;

    // Wait for the animation to complete before hiding the panel
    setTimeout(() => {
      this.showOrderPanel = false;
      this.isFadingOut = false;
    }, 500);

    if (this.selectedSociety) {
      this.router.navigate(['/orders-details', 0, this.selectedSociety.id]);
    }
  }

  cancel() {
    this.selectedSociety = null;
    this.isFadingOut = true;

    // Wait for the animation to complete before hiding the panel
    setTimeout(() => {
      this.showOrderPanel = false;
      this.isFadingOut = false;
    }, 500);
    this.closePanel.emit(); // When cancel is called, emit the close event
  }
}
