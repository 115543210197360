import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ConfigurationValue } from '../ConfigurationValue';

@Injectable({ providedIn: 'root' })
export class ConfigurationValuesService {
  private configurationValuesUrl = environment.apiUrl + '/ConfigurationValues';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  getConfigurationValues(): Observable<ConfigurationValue[]> {
    return this.http.get<ConfigurationValue[]>(this.configurationValuesUrl);
  }

  getConfigurationValue(name: string): Observable<ConfigurationValue> {
    return this.http.get<ConfigurationValue>(
      `${this.configurationValuesUrl}/${name}`
    );
  }

  createOrUpdateConfigurationValue(formData: FormData, observer: any) {
    this.http
      .post<ConfigurationValue>(this.configurationValuesUrl, formData)
      .subscribe(observer);
  }
  uploadGondolaHeadImage(formData: FormData, observer: any) {
    console.log('ConfigurationValues service uploadGondolaHeadImage');
    return this.http
      .post(`${this.configurationValuesUrl}/uploadGondolaHeadImage`, formData)
      .subscribe(observer);
  }
}
