<div class="overlay" [class.fade-out]="isFadingOut" *ngIf="showFilterPanel" (click)="cancel()"></div>
<div class="filter-panel" [class.open]="showFilterPanel">
    <div class="global-placement">

        <div class="buttons-panel">
            <div>
                <button class="btn btn-black" (click)="cancel()">Fermer</button>
            </div>
        </div>
        <h1>Filtrer</h1>
        <div class="mb-3">
            <label for="filterName" class="form-label">Nom du document</label>
            <div class="input-group">
                <input type="text" class="form-control input-search" id="filterName" id="filterName"
                    [formControl]="filterNameControl">
            </div>
        </div>
        <div class="mb-3">
            <label for="filterCategory" class="form-label">Type de document</label>
            <div class="input-group">
                <select class="form-select" id="filterCategory" [(ngModel)]="filterCategory"
                    (ngModelChange)="emitFilterChange()">
                    <option value="" disabled selected>Type de document</option>
                    <option value="L">{{helpersService.getDocumentTypeLabel("L")}}</option>
                    <option value="R">{{helpersService.getDocumentTypeLabel("R")}}</option>
                    <option value="B">{{helpersService.getDocumentTypeLabel("B")}}</option>
                    <option value="N">{{helpersService.getDocumentTypeLabel("N")}}</option>
                </select>
            </div>
        </div>

        <div class="mb-3">
            <label for="filterDate" class="form-label">Date de création</label>
            <div class="input-group">
                <input type="date" class="form-control" id="filterDate" [(ngModel)]="filterCreationDate"
                    (ngModelChange)="emitFilterChange()" placeholder="Date">
            </div>
        </div>

        <div class="buttons-panel">
            <button class="btn btn-cancel" (click)="removeFilters()">Supprimer les filtres</button>
        </div>
    </div>
</div>