import { Injectable } from '@angular/core';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ApiResult } from '../ApiResult';
import { environment } from '../../../environments/environment';
import { Product } from '../Product';

@Injectable({ providedIn: 'root' })
export class ProductsService {
  private productsUrl = environment.apiUrl + '/products';
  private changeProductPositionUrl =
    environment.apiUrl + '/UpdateProductsPositions'; // URL to web api
  private importProductsUrl = environment.apiUrl + '/importProducts'; // URL to web api
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient, public router: Router) {}

  getProducts(): Observable<Product[]> {
    console.log('products service getProducts');
    return this.http.get<Product[]>(this.productsUrl).pipe(
      map((products) =>
        products.map((product) => ({
          ...product, // This copies all the existing properties of the product
          creationDate: new Date(product.creationDate), // Converts the creationDate string to a Date object
        }))
      )
    );
  }

  getProduct(idProduct: string): Observable<Product> {
    console.log('products service getProduct');
    return this.http
      .get<Product>(this.productsUrl + '/' + idProduct)
      .pipe(tap((_) => console.log('fetched product')));
  }

  updateProduct(formData: FormData, observer) {
    console.log('products service updateProduct');
    console.log(formData);
    return this.http
      .patch<Product>(this.productsUrl, formData)
      .subscribe(observer);
  }

  createProduct(formData: FormData, observer) {
    this.http.post<Product>(this.productsUrl, formData).subscribe(observer);
  }

  deleteProduct(product: Product, observer) {
    return this.http
      .delete<any>(this.productsUrl + '/' + product.id)
      .subscribe(observer);
  }

  uploadImage(selectedFile: File): Observable<any> {
    const formData: FormData = new FormData();
    if (selectedFile) {
      formData.append('image', selectedFile, selectedFile.name);
    }

    return this.http.post<any>(this.productsUrl, formData);
  }

  updateProductPositions(products: Product[]): Observable<any> {
    const payload = products.map((product) => ({
      id: product.id,
      position: product.position,
    }));
    return this.http.post(this.changeProductPositionUrl, payload);
  }

  importProducts(products: Product[]): void {
    const url = this.importProductsUrl; // Remplacez par l'URL de votre API
    this.http.post(url, products).subscribe({
      next: (response) =>
        console.log('Produits importés avec succès', response),
      error: (error) =>
        console.error("Erreur lors de l'importation des produits", error),
    });
  }
}
