import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './dao/services/users.service';
import { User } from './dao/User';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private usersService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('user_connected')) {
      // logged in so return true
      const userConnected = this.usersService.getConnectedUser();
      if (userConnected) {
        this.usersService
          .getUser(userConnected.id.toString())
          .subscribe((user: User) => {
            localStorage.removeItem('user');
            localStorage.setItem('user', JSON.stringify(user));
          });
      }

      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
