import { Component, Input, OnInit } from '@angular/core';
import { Order } from 'src/app/dao/Order';
import { Society } from 'src/app/dao/Society';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-client-panel',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './client-panel.component.html',
  styleUrl: './client-panel.component.scss',
})
export class ClientPanelComponent implements OnInit {
  ngOnInit(): void {}
  @Input() society: Society;
  @Input() clientComment: string = '';
}
