<input type="file" (change)="onFileSelect($event)">
<button (click)="importProducts()">Importer</button>

<table>
    <tr>
        <th>Name</th>
        <th>Category</th>
        <th>Quantity Type</th>
        <th>Packaging</th>
        <th>Origin</th>
        <th>Angus</th>
        <th>In BDC</th>
    </tr>
    <tr *ngFor="let product of products">
        <td>{{product.name}}</td>
        <td>{{product.category}}</td>
        <td>{{product.quantityType}}</td>
        <td>{{product.packaging}}</td>
        <td>{{product.origin}}</td>
        <td>{{product.angus ? 'Yes' : 'No'}}</td>
        <td>{{product.isInBdc ? 'Yes' : 'No'}}</td>
    </tr>
</table>