import { Component } from '@angular/core';
import { Product } from '../dao/Product';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ProductsService } from '../dao/services/products.service';

@Component({
  selector: 'app-import-products',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './import-products.component.html',
  styleUrl: './import-products.component.scss',
})
export class ImportProductsComponent {
  constructor(
    private http: HttpClient,
    private productsService: ProductsService
  ) {} // Injectez HttpClient

  products: Product[] = [];
  onFileSelect(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const text = e.target.result as string;
        this.products = this.parseCsvData(text);
      };
      reader.readAsText(file);
    }
  }

  private categoryMapping = {
    Bœuf: 'B',
    Agneau: 'A',
    Veau: 'V',
    Porc: 'P',
    Autres: 'O',
  };

  parseCsvData(csvData: string): Product[] {
    return csvData.split('\n').map((line) => {
      const tokens = line.split(',');
      const product = new Product({
        name: tokens[0],
        category: this.categoryMapping[tokens[1]] || tokens[1],
        quantityType: this.getQuantityType(tokens.slice(2, 5)),
        packaging: this.getPackaging(tokens.slice(5, 7)),
        origin: this.getOrigin(tokens.slice(7, 10)),
        angus: tokens[10] === 'TRUE',
        isInBdc: tokens[11] === 'TRUE',
        status: 1,
        creationDate: new Date(),
      });
      return product;
    });
  }

  private getQuantityType(flags: string[]): string {
    const types = ['C', 'K', 'P'];
    return flags
      .map((flag, index) => (flag === 'TRUE' ? types[index] : ''))
      .join('');
  }

  private getPackaging(flags: string[]): string {
    const types = ['F', 'S'];
    return flags
      .map((flag, index) => (flag === 'TRUE' ? types[index] : ''))
      .join('');
  }

  private getOrigin(flags: string[]): string {
    const types = ['F', 'E', 'B'];
    return flags
      .map((flag, index) => (flag === 'TRUE' ? types[index] : ''))
      .join('');
  }

  public importProducts() {
    this.productsService.importProducts(this.products);
  }
}
