<div class="overlay" [class.fade-out]="isFadingOut" *ngIf="showOrderPanel" (click)="cancel()"></div>

<div class="create-order-panel" [class.open]="showOrderPanel">
    <div class="global-placement">
        <h1>Créer une commande pour :</h1>
        <div class="mb-3">
            <label for="clientName" class="form-label">Nom de la société :</label>
            <div class="input-group">
                <input type="text" class="form-control input-search" id="clientName" [(ngModel)]="searchTerm"
                    (ngModelChange)="searchClients()">
            </div>
        </div>
        <div *ngIf="societies.length > 0" class="society-selection">
            <div *ngFor="let society of societies" class="society-item" (click)="selectSociety(society)"
                [class.selected-society]="selectedSociety === society">
                {{ society.societyName }}
            </div>
        </div>
        <div class="buttons-panel">
            <div>
                <button class="btn btn-black" (click)="createOrder()" [disabled]="!selectedSociety">Créer une
                    commande</button>
            </div>
            <div>
                <button class="btn btn-cancel" (click)="cancel()">Annuler</button>
            </div>
        </div>
    </div>
</div>