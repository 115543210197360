import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from '../dao/services/products.service';
import { Product } from '../dao/Product';
import { HelpersService } from '../dao/services/helpers.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { ProductFilter } from '../dao/Filters';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  public helpersService: HelpersService;
  showFilterPanel: boolean = false;
  productFilter: ProductFilter;
  filterCategory: string = 'B';
  isDragging: boolean = false; // Flag to indicate drag-and-drop is active

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productsService: ProductsService,
    helpersService: HelpersService
  ) {
    this.helpersService = helpersService;
  }

  products: Product[] = [];
  displayedProducts: Product[] = [];

  ngOnInit() {
    this.productFilter = {
      nbFilters: 0,
      name: undefined,
      status: undefined,
      creationDate: undefined,
    };
    this.getProducts();
  }

  getProducts(): void {
    this.productsService.getProducts().subscribe((products) => {
      this.products = products;
      if (this.products.length > 0) {
        this.applyFilters();
      }
    });
  }

  drop(event: CdkDragDrop<string[]>): void {
    this.isDragging = true; // Set the flag to indicate dragging

    console.log(
      'event. index : ' + event.previousIndex + ' -> ' + event.currentIndex
    );

    // Filter products by the current category
    let filteredProducts = this.products.filter(
      (product) => product.category === this.filterCategory
    );

    filteredProducts = filteredProducts.sort((a, b) => a.position - b.position);
    // Get the indexes within the filtered products array
    const previousIndex = filteredProducts.indexOf(
      this.displayedProducts[event.previousIndex]
    );
    const currentIndex = filteredProducts.indexOf(
      this.displayedProducts[event.currentIndex]
    );

    moveItemInArray(filteredProducts, previousIndex, currentIndex);

    // Update the position property of each product in the filtered array
    filteredProducts.forEach((product, index) => {
      product.position = index + 1;
    });

    this.productsService.updateProductPositions(filteredProducts).subscribe({
      next: () => {
        console.log('Product positions updated successfully.');
        this.isDragging = false; // Reset the flag after the update
        this.applyFilters(); // Reapply filters after drag-and-drop
      },
      error: (error: HttpErrorResponse) => {
        console.error('Error updating product positions:', error.message);
        this.isDragging = false; // Reset the flag even if there is an error
      },
    });
    this.displayedProducts = filteredProducts;
    this.displayedProducts = filteredProducts.sort(
      (a, b) => a.position - b.position
    );
  }

  categoryFilterChanges() {
    this.applyFilters();
  }

  filterChanges(productFilter: ProductFilter) {
    this.productFilter = productFilter;
    this.applyFilters();
  }

  applyFilters() {
    if (!this.products || this.isDragging) {
      return; // Skip filtering if dragging is active
    }
    let filteredProducts = this.products;
    if (this.productFilter.name && this.productFilter.name != '') {
      filteredProducts = filteredProducts.filter((product) =>
        product.name
          .toLocaleLowerCase()
          .includes(this.productFilter.name.toLocaleLowerCase())
      );
    }
    if (this.filterCategory !== undefined && this.filterCategory != '') {
      filteredProducts = filteredProducts.filter(
        (product) => product.category == this.filterCategory
      );
    }
    if (
      this.productFilter.status !== undefined &&
      this.productFilter.status != ''
    ) {
      filteredProducts = filteredProducts.filter(
        (product) =>
          product.status === (this.productFilter.status == 'A' ? 1 : 0)
      );
    }
    if (
      this.productFilter.creationDate != undefined &&
      !this.productFilter.creationDate
        .toString()
        .toLowerCase()
        .includes('invalid')
    ) {
      const filterDate = new Date(this.productFilter.creationDate).setHours(
        0,
        0,
        0,
        0
      );
      filteredProducts = filteredProducts.filter((product) => {
        const creationDate = new Date(product.creationDate).setHours(
          0,
          0,
          0,
          0
        );
        return creationDate === filterDate;
      });
    }

    this.displayedProducts = filteredProducts;
    this.displayedProducts = filteredProducts.sort(
      (a, b) => a.position - b.position
    );
  }

  closeFilterPanel() {
    this.showFilterPanel = false;
  }
}
