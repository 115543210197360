<div class="overlay" [class.fade-out]="isFadingOut" *ngIf="showFilterPanel" (click)="cancel()"></div>
<div class="create-order-panel" [class.open]="showFilterPanel">
    <div class="global-placement">

        <div class="buttons-panel">
            <div>
                <button class="btn btn-black" (click)="cancel()">Fermer</button>
            </div>
        </div>
        <h1>Filtrer</h1>
        <div class="mb-3">
            <label for="filterId" class="form-label">ID</label>
            <div class="input-group">
                <input type="number" class="form-control input-search" id="filterId" id="filterId" min="1"
                    [formControl]="filterIdControl">
            </div>
        </div>
        <div class="mb-3">
            <label for="filterStatus" class="form-label">Statut</label>
            <div class="input-group">
                <select class="form-select" id="filterStatus" [(ngModel)]="filterStatus"
                    (ngModelChange)="emitFilterChange()">
                    <option value="" disabled selected>Statut</option>
                    <option value="B">À Valider</option>
                    <option value="T">Validée</option>
                </select>
            </div>
        </div>
        <div class="mb-3">
            <label for="filterDate" class="form-label">Date</label>
            <div class="input-group">
                <input type="date" class="form-control" id="filterDate" [(ngModel)]="filterDate"
                    (ngModelChange)="emitFilterChange()" placeholder="Date">
            </div>
        </div>

        <div class="buttons-panel">
            <button class="btn btn-cancel" (click)="removeFilters()">Supprimer les filtres</button>
        </div>
    </div>
</div>