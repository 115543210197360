<div class="around-margin">

    <h1>
        Commande {{ orderId }} - {{currentOrder.creationDate | date: 'dd.MM.yy HH:mm'
        }} - Page {{numPage}}/{{totalPage}}
    </h1>

    <app-client-panel [society]="society" [clientComment]="clientComment"></app-client-panel>

    <div class="container-fluid">
        <div class="row">

            <div class="{{categoryClass}}" *ngIf="shouldDisplayCategory('B')">
                <app-order-category-block-print category="B" [allProducts]="products" [emptyCols]="emptyCols"
                    [currentOrder]="currentOrder" [orderDetails]="false"></app-order-category-block-print>
            </div>


            <div class="{{categoryClass}}" *ngIf="shouldDisplayCategory('V')">
                <app-order-category-block-print category="V" [allProducts]="products" [emptyCols]="emptyCols"
                    [currentOrder]="currentOrder" [orderDetails]="false"></app-order-category-block-print>
            </div>
            <div class="{{categoryClass}}" *ngIf="shouldDisplayCategory('P')">
                <app-order-category-block-print category="P" [allProducts]="products" [emptyCols]="emptyCols"
                    [currentOrder]="currentOrder" [orderDetails]="false"></app-order-category-block-print>
            </div>
            <div class="{{categoryClass}}" *ngIf="shouldDisplayCategory('A')">
                <app-order-category-block-print category="A" [allProducts]="products" [emptyCols]="emptyCols"
                    [currentOrder]="currentOrder" [orderDetails]="false"></app-order-category-block-print>
            </div>

            <div class="{{categoryClass}}" *ngIf="shouldDisplayCategory('O')">
                <app-order-category-block-print category="O" [allProducts]="products" [emptyCols]="emptyCols"
                    [currentOrder]="currentOrder" [orderDetails]="false"></app-order-category-block-print>
            </div>



            <div class="{{categoryClass}}" *ngIf="shouldDisplayCategory('C')">
                <div class="comment-block">
                    <label for="commentArea">Commentaires :</label>
                    <textarea id="commentArea" class="form-control" [class.white-bg]="orderId == '0'" rows="5"
                        [value]="currentOrder !=null ? currentOrder.comment : ''"> </textarea>

                </div>
            </div>

        </div>
    </div>

</div>