import { Injectable } from '@angular/core';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { User } from '../User';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Society } from '../Society';
import { ApiResult } from '../ApiResult';
import { environment } from '../../../environments/environment';
import { AuthService } from 'src/app/auth.service';
import { ClientFilter } from '../Filters';

@Injectable({ providedIn: 'root' })
export class UserService {
  private usersUrl = environment.apiUrl + '/Users'; // URL to web api
  private societiesUrl = environment.apiUrl + '/Societies'; // URL to web api
  private userAvatarUrl = environment.apiUrl + '/UploadUserAvatar';
  private deleteUserAvatarUrl = environment.apiUrl + '/DeleteUserAvatar';
  private sendLinkByEmailUrl = environment.apiUrl + '/SendLinkByEmail';
  private sendLinkBySmsUrl = environment.apiUrl + '/SendLinkBySms';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    public router: Router,
    private authService: AuthService
  ) {}

  getUsers(
    page: number,
    pageSize: number,
    clientFilter: ClientFilter,
    sortColumn: string = 'Id', // Default sort column
    sortDirection: string = 'desc' // Default sort direction
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('sortColumn', sortColumn)
      .set('sortDirection', sortDirection);

    if (clientFilter.name) {
      params = params.set('name', clientFilter.name);
    }
    if (clientFilter.type) {
      params = params.set('type', clientFilter.type);
    }
    if (clientFilter.status) {
      params = params.set('status', clientFilter.status);
    }

    return this.http.get<any>(this.usersUrl, { params }).pipe(
      map((data) => ({
        users: data.users,
        totalUsers: data.totalUsers,
      }))
    );
  }

  getUser(idUser: string): Observable<User> {
    console.log('user service getUser ' + this.usersUrl + '/' + idUser);
    return this.http
      .get<User>(this.usersUrl + '/' + idUser)
      .pipe(tap((_) => console.log('fetched user')));
  }
  /*
  updateUser(user: User, observer) {
    console.log('user service updateUser');
    return this.http.patch<User>(this.usersUrl, user).subscribe(observer);
  }

  createUser(user: User, observer) {
    user.id = 0;
    return this.http.post<User>(this.usersUrl, user).subscribe(observer);
  }
*/
  deleteUser(user: User, observer) {
    return this.http
      .delete<any>(this.usersUrl + '/' + user.id)
      .subscribe(observer);
  }

  getConnectedUser(): User | undefined {
    try {
      const userString = localStorage.getItem('user');
      if (userString) {
        const user = JSON.parse(userString);
        return user;
      } else {
        console.log('No user found in localStorage - getConnectedUser');
        this.authService.doLogout();
        this.router.navigate(['/login']);
      }
    } catch (e) {
      console.error('Error parsing user from localStorage', e);
    }
    return undefined;
  }

  refreshConnectedUser() {
    try {
      const userString = localStorage.getItem('user');
      if (userString) {
        const user = JSON.parse(userString);
        this.getUser(user.id.toString()).subscribe((user: User) => {
          localStorage.removeItem('user');
          localStorage.setItem('user', JSON.stringify(user));
        });
      } else {
        console.log('No user found in localStorage - refreshConnectedUser');
      }
    } catch (e) {
      console.error('Error parsing user from localStorage', e);
    }
  }
  /*
  uploadAvatar(formData: FormData): Observable<any> {
    return this.http.post<any>(this.userAvatarUrl, formData);
  }

  deleteAvatar(userId: number): Observable<any> {
    console.log('Delete avatar ' + userId);
    return this.http.delete<any>(this.deleteUserAvatarUrl + '/' + userId);
  }
  */

  // Method to create a user with avatar
  createUserWithAvatar(formData: FormData): Observable<any> {
    // Note: Adjust the URL path as necessary

    formData.set('id', '0');
    return this.http.post<any>(`${this.usersUrl}`, formData);
  }

  // Method to update a user with avatar
  updateUserWithAvatar(formData: FormData): Observable<any> {
    // Note: You might need to adjust how you handle the user ID.
    // This example assumes the user ID is part of the FormData.

    return this.http.patch<any>(`${this.usersUrl}`, formData);
  }

  sendLinkByEmail(link: string, user: User): Observable<any> {
    const requestBody = { user: user, link: link };
    console.log('sendLinkByEmail 1');
    return this.http.post<any>(
      this.sendLinkByEmailUrl,
      JSON.stringify(requestBody),
      this.httpOptions
    );
    console.log('sendLinkByEmail 2');
  }

  sendLinkBySms(link: string, user: User): Observable<any> {
    const requestBody = { user: user, link: link };
    console.log('sendLinkBySms');
    return this.http.post<any>(
      this.sendLinkBySmsUrl,
      JSON.stringify(requestBody),
      this.httpOptions
    );
  }
}
