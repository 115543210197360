import { Component, OnInit } from '@angular/core';
import { Order } from '../dao/Order';
import { OrdersService } from '../dao/services/orders.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpersService } from '../dao/services/helpers.service';
import { OrderFilter } from '../dao/Filters';
import { OrderSumUp } from '../dao/OrderSumUp';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  showOrderPanel: boolean = false;
  showFilterPanel: boolean = false;
  ordersSumUp: OrderSumUp[] = [];
  displayedOrdersSumUp: OrderSumUp[] = [];
  helpersService: HelpersService;
  orderFilter: OrderFilter;

  currentPage: number = 1;
  pageSize: number = 30;
  totalOrders: number = 0;
  totalPages: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ordersService: OrdersService,
    helpersService: HelpersService
  ) {
    this.helpersService = helpersService;
  }

  ngOnInit() {
    this.orderFilter = undefined;
    //this.getOrders();
    this.loadPage(this.currentPage);
  }

  loadPage(page: number) {
    this.ordersService
      .getOrdersSumUp(page, this.pageSize, this.orderFilter)
      .subscribe((data) => {
        console.log('data in loadPage');
        console.log(data);
        this.ordersSumUp = data.orders;
        this.totalOrders = data.totalOrders;
        this.currentPage = page;
        this.totalPages = data.totalPages;
      });
  }

  togglePanel() {
    this.showOrderPanel = !this.showOrderPanel;
  }

  closeFilterPanel() {
    this.showFilterPanel = false;
  }
  /*
  getOrders(): void {
    this.ordersService.getOrdersSumUp().subscribe((ordersSumUp) => {
      console.log('GET ORDERS in component');
      this.ordersSumUp = ordersSumUp;
      this.ordersSumUp.sort((a, b) => {
        return (
          new Date(b.creationDate).getTime() -
          new Date(a.creationDate).getTime()
        );
      });
      this.displayedOrdersSumUp = ordersSumUp;
    });
  }
*/

  getPagesToShow(): number[] {
    const pagesToShow: number[] = [];
    const startPage = Math.max(1, this.currentPage - 4);
    const endPage = Math.min(this.totalPages, this.currentPage + 4);

    for (let i = startPage; i <= endPage; i++) {
      pagesToShow.push(i);
    }

    return pagesToShow;
  }

  goToPage(page: number): void {
    // Implement your logic to navigate to the selected page
    console.log('Navigating to page:', page);
    this.currentPage = page;
    this.loadPage(this.currentPage);
  }

  get totalNbPages(): number {
    return Math.ceil(this.totalOrders / this.pageSize);
  }

  createDispute() {
    this.router.navigate(['/documents-details']);
  }

  filterChanges(orderFilter) {
    this.orderFilter = orderFilter;
    this.applyFilters();
  }

  applyFilters() {
    console.log('APPLY FILTERS');
    // Reset current page to 1 when filters are applied
    this.currentPage = 1;

    this.loadPage(this.currentPage);
  }
}
