import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../dao/services/users.service';
import { UserSociety } from '../dao/UserSociety';
import { ClientFilter } from '../dao/Filters';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent implements OnInit {
  userSocieties: UserSociety[] = [];
  displayedUserSocieties: UserSociety[] = [];
  showFilterPanel: boolean = false;
  clientFilter: ClientFilter;
  sortedColumn: string = 'Id';
  sortDirection: string = 'desc';

  currentPage: number = 1;
  pageSize: number = 30;
  totalUsers: number = 0;
  totalPages: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.clientFilter = {
      name: '',
      type: '',
      status: '',
      nbFilters: 0,
    };
    this.loadPage(this.currentPage);
  }

  sort(column: string) {
    // Toggle sort direction if the same column is clicked
    if (this.sortedColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      // Otherwise, sort by the new column in ascending order
      this.sortedColumn = column;
      this.sortDirection = 'asc';
    }
    // Reload the current page with the new sorting
    this.loadPage(this.currentPage);
  }

  sortingDirection(column: string): string {
    if (this.sortedColumn === column) {
      return this.sortDirection; // returns 'asc' or 'desc' based on current sorting direction
    }
    return ''; // default state when the column is not actively sorted
  }

  isSorted(column: string): boolean {
    return this.sortedColumn === column;
  }

  loadPage(page: number) {
    console.log('LOAD PAGE ' + this.sortedColumn + ' ' + this.sortDirection);
    this.userService
      .getUsers(
        page,
        this.pageSize,
        this.clientFilter,
        this.sortedColumn,
        this.sortDirection
      )
      .subscribe((data) => {
        this.userSocieties = this.processUsers(data.users);
        this.totalUsers = data.totalUsers;
        this.currentPage = page;
        this.totalPages = Math.ceil(this.totalUsers / this.pageSize);
        this.displayedUserSocieties = this.userSocieties;
      });
  }

  processUsers(users: any[]): UserSociety[] {
    let userSocieties: UserSociety[] = [];
    for (let i = 0; i < users.length; i++) {
      if (users[i].societies == null || users[i].societies.length == 0) {
        var userSociety: UserSociety = {} as UserSociety;

        userSociety.id = users[i].id;
        userSociety.groupName = users[i].isAdmin ? '' : users[i].groupName;
        userSociety.status = users[i].status;
        userSociety.isAdmin = users[i].isAdmin;
        userSociety.code2b = users[i].isAdmin
          ? users[i].firstName + ' ' + users[i].lastName
          : '';
        userSociety.societyName = '';
        userSociety.callName = '';
        userSocieties.push(userSociety);
      } else {
        for (let j = 0; j < users[i].societies.length; j++) {
          var userSociety: UserSociety = {} as UserSociety;

          userSociety.id = users[i].id;
          userSociety.groupName = users[i].isAdmin ? '' : users[i].groupName;
          userSociety.isAdmin = users[i].isAdmin;
          userSociety.status = users[i].status;
          userSociety.code2b = users[i].isAdmin
            ? users[i].firstName + ' ' + users[i].lastName
            : users[i].societies[j].code2b;
          userSociety.societyName = users[i].isAdmin
            ? ''
            : users[i].societies[j].societyName;
          userSociety.callName = users[i].isAdmin
            ? ''
            : users[i].societies[j].callName;
          userSocieties.push(userSociety);
        }
      }
    }
    return userSocieties;
  }

  closeFilterPanel() {
    this.showFilterPanel = false;
  }

  filterChanges(clientFilter: ClientFilter) {
    this.clientFilter = clientFilter;
    this.applyFilters();
  }

  applyFilters() {
    this.currentPage = 1;
    this.loadPage(this.currentPage);
  }

  getPagesToShow(): number[] {
    const pagesToShow: number[] = [];
    const startPage = Math.max(1, this.currentPage - 4);
    const endPage = Math.min(this.totalPages, this.currentPage + 4);

    for (let i = startPage; i <= endPage; i++) {
      pagesToShow.push(i);
    }

    return pagesToShow;
  }

  goToPage(page: number): void {
    this.currentPage = page;
    this.loadPage(this.currentPage);
  }
}
