export const environment = {
  production: true,
  apiUrl: 'https://soaapi.josh-digital.net/soa-api',

  productImagesUrl: 'https://soaapi.josh-digital.net/product-images/',
  avatarImagesUrl: 'https://soaapi.josh-digital.net/avatar-images/',
  documentFilesUrl: 'https://soaapi.josh-digital.net/document-files/',
  clientLink: 'https://app.soa.josh-digital.net/?token=',
  gondolaHeadFilesUrl: 'https://soaapi.josh-digital.net/gondola-head/',
};
