import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, Observable, throwError } from 'rxjs';
import { LoginResult } from './dao/LoginResult';
import { User } from './dao/User';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  endpoint: string = environment.apiUrl;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private http: HttpClient, public router: Router) {}
  // Sign-in
  async login(email: string, password: string): Promise<User> {
    const encodedEmail = encodeURIComponent(email);
    const encodedPassword = encodeURIComponent(password);

    console.log(
      'login',
      `${this.endpoint}/security/login?type=2&login=${encodedEmail}&password=${encodedPassword}`
    );
    try {
      const res = await this.http
        .post<LoginResult>(
          `${this.endpoint}/security/login?type=2&login=${encodedEmail}&password=${encodedPassword}`,
          {}
        )
        .toPromise();

      if (res != null && res.user != null) {
        localStorage.setItem('access_token', res.jwtToken);
        localStorage.setItem('user_connected', res.user.email);
        localStorage.setItem('user', JSON.stringify(res.user));
        console.log(res);

        return res.user;
      } else {
        this.doLogout();
      }
    } catch (ex) {}
    return null;
  }

  getToken() {
    return localStorage.getItem('access_token');
  }
  get isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return authToken !== null ? true : false;
  }
  doLogout() {
    let removeToken = localStorage.removeItem('access_token');
    localStorage.removeItem('user_connected');
    localStorage.removeItem('user');
    if (removeToken == null) {
      this.router.navigate(['log-in']);
    }
  }
  // Error
  handleError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }
  public disconnect() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_connected');
    localStorage.removeItem('user');
  }
}
