import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ClientsComponent } from './clients/clients.component';
import { AuthInterceptor } from './authconfig.interceptor';
import { ClientsDetailsComponent } from './clients/clients-details/clients-details.component';
import { ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { LogoutComponent } from './logout/logout.component';
import { OrdersComponent } from './orders/orders.component';
import { ProductsComponent } from './products/products.component';
import { StocksComponent } from './stocks/stocks.component';
import { DocumentsComponent } from './documents/documents.component';
import { ProductsDetailsComponent } from './products/products-details/products-details.component';
import { FilterButtonComponent } from './filters/filter-button/filter-button.component';
import { ChooseClientPanelComponent } from './orders/choose-client-panel/choose-client-panel.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DocumentsDetailsComponent } from './documents/documents-details/documents-details.component';
import { ChooseOrderFilterPanelComponent } from './filters/choose-order-filter-panel/choose-order-filter-panel.component';
import { ChooseProductFilterPanelComponent } from './filters/choose-product-filter-panel/choose-product-filter-panel.component';
import { ChooseDocumentFilterPanelComponent } from './filters/choose-document-filter-panel/choose-document-filter-panel.component';
import { ChooseClientFilterPanelComponent } from './filters/choose-client-filter-panel/choose-client-filter-panel.component';
import { FormsModule } from '@angular/forms'; // Import FormsModule
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { LoggingInterceptor } from './logging-interceptor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'; // Adjust the path as necessary

registerLocaleData(localeFr, 'fr');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    ClientsComponent,
    ClientsDetailsComponent,
    LogoutComponent,
    OrdersComponent,
    ProductsComponent,
    StocksComponent,
    DocumentsComponent,
    ProductsDetailsComponent,
    DocumentsDetailsComponent,
    FilterButtonComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr',
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    QRCodeModule,
    NgbModule,
    FormsModule,
    FeatherModule,
    FeatherModule.pick(allIcons),
    RouterModule.forRoot(Approutes),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ChooseClientPanelComponent,
    DragDropModule,
    ChooseOrderFilterPanelComponent,
    ChooseProductFilterPanelComponent,
    ChooseDocumentFilterPanelComponent,
    ChooseClientFilterPanelComponent,
    FontAwesomeModule,
  ],
})
export class AppModule {}
