<div class="card">
    <div class="card-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-4">
                    <div class="info-box">
                        <label>Nom d'appel :</label>
                        <span>{{ society?.callName }}</span>
                    </div>
                    <div class="info-box ">
                        <label>Nom de société :</label>
                        <span>{{ society?.societyName }}</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="info-box">
                        <label>Adresse :</label>
                        <span>{{ society?.address }}</span>
                    </div>
                    <div class="info-box">
                        <label>Téléphone :</label>
                        <span>{{ society?.client.phone }}</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="info-box comment-box">
                        <label>Commentaires client :</label>
                        <textarea class="form-control" placeholder="" id="clientComment" disabled="disabled"
                            [(ngModel)]="clientComment"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>