import { Component, OnInit } from '@angular/core';
import { DocumentNote } from '../dao/DocumentNote';
import { DocumentFilter } from '../dao/Filters';
import { DocumentsService } from '../dao/services/documents.service';
import { HelpersService } from '../dao/services/helpers.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  helpersService: HelpersService;
  documents: DocumentNote[] = [];
  documentFilter: DocumentFilter;
  displayedDocuments: DocumentNote[] = [];
  showFilterPanel: boolean = false;

  currentPage: number = 1;
  pageSize: number = 30;
  totalDocuments: number = 0;
  totalPages: number = 0;

  ngOnInit() {
    this.documentFilter = {
      name: '',
      type: '',
      creationDate: undefined,
      nbFilters: 0,
    };
    this.loadPage(this.currentPage);
  }

  constructor(
    helpersService: HelpersService,
    private documentsService: DocumentsService
  ) {
    this.helpersService = helpersService;
  }

  closeFilterPanel() {
    this.showFilterPanel = false;
  }

  loadPage(page: number) {
    this.documentsService
      .getDocuments(page, this.pageSize, this.documentFilter)
      .subscribe((data) => {
        console.log('data.documents', data.documents);
        console.log('data.totalDocuments', data.totalDocuments);
        this.documents = data.documents;
        this.totalDocuments = data.totalDocuments;
        this.currentPage = page;
        this.totalPages = Math.ceil(this.totalDocuments / this.pageSize);
      });
  }

  filterChanges(documentFilter) {
    this.documentFilter = documentFilter;
    this.applyFilters();
  }

  applyFilters() {
    this.currentPage = 1;
    this.loadPage(this.currentPage);
  }

  getPagesToShow(): number[] {
    const pagesToShow: number[] = [];
    const startPage = Math.max(1, this.currentPage - 4);
    const endPage = Math.min(this.totalPages, this.currentPage + 4);

    for (let i = startPage; i <= endPage; i++) {
      pagesToShow.push(i);
    }

    return pagesToShow;
  }

  goToPage(page: number): void {
    this.currentPage = page;
    this.loadPage(this.currentPage);
  }
}
