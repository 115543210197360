<div class="preloader" *ngIf="isLoading">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>


<a href="products" class="returnToPrevious"><i-feather name="arrow-left"></i-feather> Retour au catalogue</a>

<div class="card">
    <div class="container-fluid py-3">
        <div style="margin-bottom: 1rem;display: flex;place-content: end;">
            <button type="button" class="btn btn-black" (click)="validate()">Enregistrer
            </button>
            <button type="button" class="btn btn-white" (click)="deleteProductWithConfirmation()"> Supprimer le produit
            </button>
        </div>
        <div *ngIf="errorText" [@fadeInOut] class="alert show customize-alert fs-4 alert-danger fade">
            {{errorText}}
            <button type="button" aria-label="Close" class="btn-close btn-absolute-right"
                (click)="deleteMessages()"></button>
        </div>
        <div *ngIf="successText" [@fadeInOut] class="alert show customize-alert fs-4 alert-success fade">
            {{successText}}
            <button type="button" aria-label="Close" class="btn-close btn-absolute-right"
                (click)="deleteMessages()"></button>
        </div>




        <div class="row justify-content-left my-5">
            <div class="col-5">
                <form *ngIf="reactiveForm" [formGroup]="reactiveForm" #form="ngForm">
                    <input type="hidden" id="id" name="id" formControlName="id" />
                    <input type="hidden" id="image" name="image" formControlName="image" />
                    <input type="hidden" id="position" name="position" formControlName="position" />
                    <input type="hidden" id="creationDate" name="creationDate" formControlName="creationDate" />
                    <div class="row">
                        <div class="col mb-2">
                            <label for="name" class="form-label">Nom du produit :</label>
                            <input type="text" id="name" name="name" formControlName="name" placeholder="Nom du produit"
                                minlength="1" maxlength="250" class="form-control form-control-sm"
                                [class.is-invalid]="name.invalid && (name.dirty || name.touched)">
                            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">

                                <div *ngIf="name.errors?.['maxlength']">
                                    Le nom du produit ne doit pas excéder 250 caractères.
                                </div>
                                <div *ngIf="name.errors?.['required']">
                                    Le nom du produit est obligatoire
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col mb-2">
                            <label for="shortName" class="form-label">Nom abrégé du produit :</label>
                            <input type="text" id="shortName" name="shortName" formControlName="shortName"
                                placeholder="Nom abrégé du produit" minlength="1" maxlength="40"
                                class="form-control form-control-sm"
                                [class.is-invalid]="shortName.invalid && (shortName.dirty || shortName.touched)">
                            <div *ngIf="shortName.invalid && (shortName.dirty || shortName.touched)"
                                class="invalid-feedback">

                                <div *ngIf="shortName.errors?.['maxlength']">
                                    Le nom abrégé du produit ne doit pas excéder 40 caractères.
                                </div>
                                <div *ngIf="shortName.errors?.['required']">
                                    Le nom abrégé du produit est obligatoire
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col mb-2">
                            <label for="category" class="form-label">Catégorie :</label>
                            <select formControlName="category" class="form-control form-control-sm form-select"
                                style="appearance: auto;"
                                [class.is-invalid]="category.invalid && (category.dirty || category.touched)">
                                <option value="B">Boeuf</option>
                                <option value="P">Porc</option>
                                <option value="V">Veau</option>
                                <option value="A">Agneau</option>
                                <option value="O">Autres</option>
                            </select>
                            <div *ngIf="category.invalid && (category.dirty || category.touched)"
                                class="invalid-feedback">
                                <div *ngIf="name.errors?.['required']">
                                    La catégorie du produit est obligatoire
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col mb-2">
                            <label for="status" class="form-label">Statut :</label>
                            <select formControlName="status" class="form-control form-control-sm form-select"
                                style="appearance: auto;"
                                [class.is-invalid]="status.invalid && (status.dirty || status.touched)">
                                <option value="1">Actif</option>
                                <option value="0">Inactif</option>
                            </select>
                            <div *ngIf="status.invalid && (status.dirty || status.touched)" class="invalid-feedback">
                                <div *ngIf="name.errors?.['required']">
                                    Le statut du produit est obligatoire
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col mb-2">
                            <label class="form-label">Type de quantité :</label>
                            <div class="form-control form-control-sm"
                                style="display: inline-flex;border:0; padding-left: 0;">
                                <div *ngFor="let value of possibleValuesForQuantityType"
                                    style="width: auto;padding-right: 10px;">
                                    <label class="btn "
                                        [ngClass]="{ 'btn-soa': product.quantityType.includes(value), 'btn-outline-soa': !product.quantityType.includes(value) }">
                                        <input type="checkbox" [value]="value"
                                            [checked]="product.quantityType.includes(value)"
                                            (change)="onValueQuantityTypeChanged($event, value)" style="display: none;">
                                        {{ helpersService.getQuantityTypeLabel(value) }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col mb-2">
                            <label class="form-label">Conditionnement :</label>
                            <div class="form-control form-control-sm"
                                style="display: inline-flex;border:0; padding-left: 0;">
                                <div *ngFor="let value of possibleValuesForPackaging"
                                    style="width: auto;padding-right: 10px;">
                                    <label class="btn "
                                        [ngClass]="{ 'btn-soa': product.packaging.includes(value), 'btn-outline-soa': !product.packaging.includes(value) }">
                                        <input type="checkbox" [value]="value"
                                            [checked]="product.packaging.includes(value)"
                                            (change)="onValuePackagingChanged($event, value)" style="display: none;">
                                        {{ helpersService.getPackagingLabel(value) }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col mb-2">
                            <label class="form-label">Origine :</label>
                            <div class="form-control form-control-sm"
                                style="display: inline-flex;border:0; padding-left: 0;">
                                <div *ngFor="let value of possibleValuesForOrigin"
                                    style="width: auto;padding-right: 10px;">
                                    <label class="btn "
                                        [ngClass]="{ 'btn-soa': product.origin.includes(value), 'btn-outline-soa ': !product.origin.includes(value) }">
                                        <input type="checkbox" [value]="value"
                                            [checked]="product.origin.includes(value)"
                                            (change)="onValueOriginChanged($event, value)" style="display: none;">
                                        {{ helpersService.getOriginLabel(value) }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="reactiveForm.get('category')?.value === 'B'">
                        <div class="col mb-2 inline-flex">
                            <label for="angus" class="form-label form-label-custom-checkbox">Angus :</label>
                            <label class="custom-checkbox-container">
                                <input type="checkbox" id="angus" name="angus" formControlName="angus" class="" hidden>
                                <span class="custom-checkbox"></span>
                                <label for="angus" class="yes custom-checkbox-label">Oui</label>
                            </label>


                        </div>
                    </div>

                    <div class="row">
                        <div class="col mb-2 inline-flex">
                            <label for="opportunity" class="form-label form-label-custom-checkbox">Opportunité :</label>


                            <label class="custom-checkbox-container">
                                <input type="checkbox" id="opportunity" name="opportunity" formControlName="opportunity"
                                    class="" hidden>
                                <span class="custom-checkbox"></span>
                                <label for="opportunity" class="yes custom-checkbox-label">Oui</label>
                            </label>


                        </div>
                    </div>


                    <div class="row">
                        <div class="col mb-2 inline-flex">

                            <label for="isInBdc" class="form-label form-label-custom-checkbox">Présence BDC :</label>
                            <label class="custom-checkbox-container">
                                <input type="checkbox" id="isInBdc" name="isInBdc" formControlName="isInBdc" class=""
                                    hidden>
                                <span class="custom-checkbox"></span>
                                <label for="isInBdc" class="yes custom-checkbox-label">Oui</label>
                            </label>

                        </div>
                    </div>
                    <input type="file" id="fileInput" (change)="onFileSelected($event)" accept="image/*"
                        style="display: none;">
                </form>
            </div>
            <div class="col-2"></div>
            <div class="col-5 " style="text-align: right;">
                <img id="selectedImage" class="selectedImage " src="{{imageUrl}}" />
                <div>

                    <label for="fileInput" class="btn btn-black">
                        <i class="fa-solid fa-pencil"></i>
                        Modifier
                    </label>
                </div>
            </div>

        </div>

    </div>
</div>