import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DocumentFilter } from '../../dao/Filters';
import { HelpersService } from 'src/app/dao/services/helpers.service';

@Component({
  selector: 'app-choose-document-filter-panel',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './choose-document-filter-panel.component.html',
  styleUrl: './choose-document-filter-panel.component.scss',
})
export class ChooseDocumentFilterPanelComponent {
  @Output() closeFilterPanel = new EventEmitter<void>(); // Emit an event without any value
  @Output() filterValueChanged = new EventEmitter<DocumentFilter>();

  constructor(private router: Router, helpersService: HelpersService) {
    this.initializeValueChanges();
    this.helpersService = helpersService;
  }

  @Input() showFilterPanel = false;
  filterName: string = '';
  filterCategory: string = '';
  filterCreationDate: string = '';
  isFadingOut: boolean;
  helpersService: HelpersService;
  filterNameControl = new FormControl('');

  initializeValueChanges() {
    this.filterNameControl.valueChanges
      .pipe(
        debounceTime(500), // Wait for 500ms of inactivity
        distinctUntilChanged() // Emit only if the value has changed
      )
      .subscribe((value) => {
        this.filterName = value;
        this.emitFilterChange();
      });
  }

  emitFilterChange() {
    console.log('EMIT FILTER CHANGE');
    let nbFilters = 0;
    if (this.filterName != undefined && this.filterName != '') {
      nbFilters++;
    }
    if (this.filterCategory != undefined && this.filterCategory != '') {
      nbFilters++;
    }

    if (this.filterCreationDate != undefined && this.filterCreationDate != '') {
      nbFilters++;
    }
    this.filterValueChanged.emit({
      nbFilters: nbFilters,
      name: this.filterName,
      type: this.filterCategory,
      creationDate: new Date(this.filterCreationDate),
    });
  }

  /*
export interface ProductFilter extends GenericFilter {
  name: string;
  category: string;
  status: String;
  creationDate: Date;
}
*/

  cancel() {
    this.isFadingOut = true;

    // Wait for the animation to complete before hiding the panel
    setTimeout(() => {
      this.showFilterPanel = false;
      this.isFadingOut = false;
    }, 500);
    this.closeFilterPanel.emit(); // When cancel is called, emit the close event
  }

  removeFilters() {
    this.filterNameControl.reset('');
    this.filterCategory = '';
    this.filterCreationDate = '';
    this.emitFilterChange();
  }
}
