import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  HostListener,
  ChangeDetectorRef,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NgbCollapseModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { User } from 'src/app/dao/User';
import { AvatarService } from 'src/app/dao/services/avatar.service';
import { UserService } from 'src/app/dao/services/users.service';
import { BreadcrumbComponent } from 'src/app/shared/breadcrumb/breadcrumb.component';
import { VerticalNavigationComponent } from 'src/app/shared/vertical-header/vertical-navigation.component';
import { VerticalSidebarComponent } from 'src/app/shared/vertical-sidebar/vertical-sidebar.component';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-full-layout',
  standalone: true,
  imports: [
    RouterModule,
    VerticalNavigationComponent,
    VerticalSidebarComponent,
    BreadcrumbComponent,
    NgScrollbarModule,
    NgbNavModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgbCollapseModule,
  ],
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss'],
})
export class FullComponent implements OnInit {
  active = 1;

  constructor(
    public router: Router,
    private userService: UserService,
    private avatarService: AvatarService,
    private cdr: ChangeDetectorRef
  ) {}

  tabStatus = 'justified';

  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;
  public defaultAvatar: boolean;
  avatarUrl: string;

  options = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // two possible values: vertical, horizontal
    sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
  };

  getDisplayedName() {
    if (this.userService.getConnectedUser()) {
      const user: User = this.userService.getConnectedUser();
      return user.firstName + ' ' + user.lastName;
    }
    return '';
  }

  changeAvatarUrl() {
    const user: User = this.userService.getConnectedUser();
    this.defaultAvatar = !(
      user != null &&
      user.avatar != null &&
      user.avatar != undefined &&
      user.avatar != 'null'
    );
    this.avatarUrl = this.defaultAvatar
      ? environment.avatarImagesUrl + 'default_avatar.png'
      : environment.avatarImagesUrl + user.avatar;
    this.cdr.detectChanges();
  }

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    this.defaultSidebar = this.options.sidebartype;

    this.handleSidebar();

    this.avatarService.getAvatarContent().subscribe((content) => {
      const selectedAvatarElement = document.getElementById(
        'menuAvatar'
      ) as HTMLImageElement;
      if (
        selectedAvatarElement &&
        content != null &&
        content != undefined &&
        content != ''
      ) {
        selectedAvatarElement.src = content;
      }

      this.userService.refreshConnectedUser();
      console.log('detectChanges in full component before');
      this.avatarUrl = content;
      this.cdr.detectChanges();
      console.log('detectChanges in full component after');
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: string) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case 'full':
      case 'iconbar':
        if (this.innerWidth < 900) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case 'overlay':
        if (this.innerWidth < 900) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case 'full':
      case 'iconbar':
        this.options.sidebartype = 'mini-sidebar';
        break;

      case 'overlay':
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case 'mini-sidebar':
        if (this.defaultSidebar === 'mini-sidebar') {
          this.options.sidebartype = 'full';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  handleClick(event: boolean) {
    this.showMobileMenu = event;
  }
}
