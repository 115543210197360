import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Society } from 'src/app/dao/Society';
import { Product } from 'src/app/dao/Product';
import { Order } from 'src/app/dao/Order';
import { SocietiesService } from 'src/app/dao/services/societies.service';
import { ProductsService } from 'src/app/dao/services/products.service';
import { OrdersService } from 'src/app/dao/services/orders.service';

@Injectable({ providedIn: 'root' })
export class OrderPrintResolver {
  constructor(
    private societiesService: SocietiesService,
    private productsService: ProductsService,
    private ordersService: OrdersService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<[Society, Product[], Order]> {
    const societyId = route.params['societyId'];
    const orderId = route.params['orderId'];
    const society$ = this.societiesService.getSociety(societyId);
    const products$ = this.productsService.getProducts();
    const order$ = orderId ? this.ordersService.getOrder(orderId) : null;
    return forkJoin([society$, products$, order$]).pipe(
      map(([society, products, order]) => [society, products, order])
    );
  }
}
