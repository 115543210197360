import { Injectable } from '@angular/core';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { User } from '../User';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Society } from '../Society';
import { ApiResult } from '../ApiResult';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SocietiesService {
  private societiesUrl = environment.apiUrl + '/Societies'; // URL to web api
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient, public router: Router) {}

  getSocieties(): Observable<Society[]> {
    console.log('user service getSocieties');
    return this.http
      .get<Society[]>(this.societiesUrl)
      .pipe(tap((_) => console.log('fetched societies')));
  }
  getSocietiesWithQuery(query: string): Observable<Society[]> {
    const params = new HttpParams().set('query', query);
    return this.http.get<Society[]>(this.societiesUrl, { params });
  }

  getSociety(idSociety: string): Observable<Society> {
    return this.http
      .get<Society>(this.societiesUrl + '/' + idSociety)
      .pipe(tap((_) => console.log('fetched society')));
  }
}
