<div class="overlay" [class.fade-out]="isFadingOut" *ngIf="showFilterPanel" (click)="cancel()"></div>
<div class="filter-panel" [class.open]="showFilterPanel">
    <div class="global-placement">

        <div class="buttons-panel">
            <div>
                <button class="btn btn-black" (click)="cancel()">Fermer</button>
            </div>
        </div>
        <h1>Filtrer</h1>

        <div class="mb-3">
            <label for="filterName" class="form-label">Nom d'appel</label>
            <div class="input-group">
                <input type="text" class="form-control input-search" id="filterName" id="filterName"
                    [formControl]="filterNameControl">
            </div>
        </div>
        <div class="mb-3">
            <label for="filterStatus" class="form-label">Statut</label>
            <div class="input-group">
                <select class="form-select" id="filterStatus" [(ngModel)]="filterStatus"
                    (ngModelChange)="emitFilterChange()">
                    <option value="" disabled selected>Statut</option>
                    <option value="A">Actif</option>
                    <option value="I">Inactif</option>
                </select>
            </div>
        </div>

        <div class="mb-3">
            <label for="filterProfile" class="form-label">Profil</label>
            <div class="input-group">
                <select class="form-select" id="filterProfile" [(ngModel)]="filterProfile"
                    (ngModelChange)="emitFilterChange()">
                    <option value="" disabled selected>Profil</option>
                    <option value="A">Admin</option>
                    <option value="C">Client</option>
                </select>
            </div>
        </div>

        <div class="buttons-panel">
            <button class="btn btn-cancel" (click)="removeFilters()">Supprimer les filtres</button>
        </div>
    </div>
</div>